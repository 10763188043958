import { useSuspenseQuery } from "@apollo/client";
import _ from "lodash";
import { Loader2, TimerReset } from "lucide-react";
import React from "react";
import { useParams } from "react-router-dom";

import { SimpleTooltip } from "@/DesignSystem/nanny/SimpleTooltip/SimpleTooltip";
import { AnalysisType, ModelResultsFilter, gql } from "@/apis/nannyml";
import { FilterMetrics, FilterTags } from "@/components/Filters";
import { LabeledField } from "@/components/LabeledField";
import { PlotView } from "@/components/PlotView";
import { PanelItem } from "@/components/ResizablePanel";
import { Button } from "@/components/common/Button";
import { InformationModalChip } from "@/components/dashboard/InformationModal/InformationModalChip";
import { ActiveDateRangeResultPlot, ResultPlotTitle } from "@/components/monitoring";
import {
  PlotConfigContextProvider,
  PlotDatasetsConfig,
  PlotElementsConfig,
  PlotTypeConfig,
} from "@/components/monitoring/PlotConfig";
import {
  FilterAlertStatus,
  FilterColumns,
  FilterDateRangePicker,
  FilterDateRangeSlider,
  FilterPerformancePlotMode,
  FilterSortOrder,
  ResultFilterContextProvider,
  useDateFilterContext,
} from "@/components/monitoring/ResultFilters";
import { FilterSegments } from "@/components/monitoring/ResultFilters/FilterSegments";
import { PlotType, SortOrder } from "@/constants/enums";
import { ResultView } from "@/domains/monitoring";
import {
  conceptShiftMetricLabels,
  dataQualityMetricLabels,
  performanceMetricLabels,
  summaryStatsMetricLabels,
  multivariateDriftMethodLabels,
  distanceMethodLabels,
  statisticalMethodLabels,
  resultViewLabels,
} from "@/formatters/monitoring";
import { selectWhere } from "@/lib/typesUtils";

const getModelResultsQuery = gql(/* GraphQL */ `
  query GetModelResults($modelId: Int!, $filter: [ModelResultsFilter!]) {
    monitoring_model(id: $modelId) {
      results(filter: $filter) {
        __typename
        id
        ... on TimeSeriesResult {
          ...ResultPlot
          ...ResultFilter
        }
      }
    }
  }
`);

type ResultViewConfig = {
  resultFilter: ModelResultsFilter;
  filterElements: { label: React.ReactNode; element: React.ReactNode }[];
  plotElements: { label: React.ReactNode; element: React.ReactNode }[];
  sortOptions: SortOrder[];
};

const commonFilters = [
  {
    label: "Alert status",
    element: <FilterAlertStatus />,
  },
  {
    label: "Tags",
    element: <FilterTags />,
  },
];

const commonPlotElements = [
  {
    label: "Datasets",
    element: <PlotDatasetsConfig />,
  },
  {
    label: "Plot elements",
    element: <PlotElementsConfig />,
  },
];

const SegmentTitle = () => (
  <>
    Segments <InformationModalChip infoName="Segmentation" className="inline-flex ml-0" />
  </>
);

const views: Record<ResultView, ResultViewConfig> = {
  [ResultView.ConceptDrift]: {
    resultFilter: { analysisTypes: [AnalysisType.ConceptShift] },
    filterElements: [
      {
        label: <SegmentTitle />,
        element: <FilterSegments />,
      },
      {
        label: "Metrics",
        element: <FilterMetrics metricLabels={conceptShiftMetricLabels} />,
      },
      ...commonFilters,
    ],
    plotElements: [
      {
        label: "Plot format",
        element: <PlotTypeConfig plotTypes={[PlotType.Line, PlotType.Step]} />,
      },
      ...commonPlotElements,
    ],
    sortOptions: [SortOrder.Metric, SortOrder.NrOfAlers, SortOrder.RecencyOfAlerts],
  },
  [ResultView.CovariateShift]: {
    resultFilter: { analysisTypes: [AnalysisType.FeatureDrift, AnalysisType.SummaryStats] },
    filterElements: [
      {
        label: <SegmentTitle />,
        element: <FilterSegments />,
      },
      {
        label: "Methods",
        element: (
          <FilterMetrics
            metricLabels={[
              { labels: multivariateDriftMethodLabels, title: "Multivariate" },
              { labels: distanceMethodLabels, title: "Distance measures" },
              { labels: statisticalMethodLabels, title: "Statistical measures" },
              { labels: summaryStatsMetricLabels, title: "Summary statistics" },
            ]}
          />
        ),
      },
      {
        label: "Columns",
        element: <FilterColumns />,
      },
      ...commonFilters,
    ],
    plotElements: [
      {
        label: "Plot format",
        element: <PlotTypeConfig plotTypes={[PlotType.Line, PlotType.Step, PlotType.Distribution]} />,
      },
      ...commonPlotElements,
    ],
    sortOptions: [SortOrder.Column, SortOrder.Method, SortOrder.NrOfAlers, SortOrder.RecencyOfAlerts],
  },
  [ResultView.DataQuality]: {
    resultFilter: { analysisTypes: [AnalysisType.DataQuality] },
    filterElements: [
      {
        label: <SegmentTitle />,
        element: <FilterSegments />,
      },
      {
        label: "Metrics",
        element: <FilterMetrics metricLabels={dataQualityMetricLabels} />,
      },
      {
        label: "Columns",
        element: <FilterColumns />,
      },
      ...commonFilters,
    ],
    plotElements: [
      {
        label: "Plot format",
        element: <PlotTypeConfig plotTypes={[PlotType.Line, PlotType.Step]} />,
      },
      ...commonPlotElements,
    ],
    sortOptions: [SortOrder.Column, SortOrder.Method, SortOrder.NrOfAlers, SortOrder.RecencyOfAlerts],
  },
  [ResultView.Performance]: {
    resultFilter: { analysisTypes: [AnalysisType.EstimatedPerformance, AnalysisType.RealizedPerformance] },
    filterElements: [
      {
        label: <SegmentTitle />,
        element: <FilterSegments />,
      },
      {
        label: "Metrics",
        element: <FilterMetrics metricLabels={performanceMetricLabels} />,
      },
      {
        label: "Performance type",
        element: <FilterPerformancePlotMode />,
      },
      ...commonFilters,
    ],
    plotElements: [
      {
        label: "Plot format",
        element: <PlotTypeConfig plotTypes={[PlotType.Line, PlotType.Step]} />,
      },
      ...commonPlotElements,
    ],
    sortOptions: [SortOrder.Metric, SortOrder.NrOfAlers, SortOrder.RecencyOfAlerts],
  },
};

export const ModelResultRouteName = () => {
  const { resultView } = useParams();
  if (!resultView || !(resultView in views)) {
    throw new Error(`Invalid result view: ${resultView}`);
  }
  return <>{resultViewLabels[resultView as ResultView]}</>;
};

const ResultLoadingWrapper = (WrappedComponent: React.ComponentType) => () =>
  (
    <React.Suspense
      fallback={
        <div className={"w-full h-full flex flex-col items-center justify-center"}>
          <Loader2 className="animate-spin text-highlightDeep" size={36} />
          <p>Loading results</p>
        </div>
      }
    >
      <WrappedComponent />
    </React.Suspense>
  );

export const ModelResultRoute = ResultLoadingWrapper(() => {
  const { modelId, resultView } = useParams();

  if (!resultView || !(resultView in views)) {
    throw new Error(`Invalid result view: ${resultView}`);
  } else if (!modelId) {
    throw new Error("Model ID is required to plot results");
  }

  return (
    <PlotConfigContextProvider storeName={resultView}>
      <ModelResultView modelId={parseInt(modelId)} view={resultView as ResultView} />
    </PlotConfigContextProvider>
  );
});

const ModelResultView = ({ modelId, view }: { modelId: number; view: ResultView }) => {
  const { filterElements, plotElements, resultFilter, sortOptions } = views[view];
  const {
    data: { monitoring_model: model },
  } = useSuspenseQuery(getModelResultsQuery, {
    variables: { modelId, filter: { ...resultFilter } },
  });
  const results = model?.results.filter(selectWhere("__typename", "TimeSeriesResult")) ?? [];

  return (
    <ResultFilterContextProvider filterStoreName={`${modelId}.${view}`} results={results}>
      <PlotView<(typeof results)[number]>
        autoSaveId="ModelResultView"
        filters={filterElements.map((props, idx) => (
          <PanelItem key={idx} title={props.label}>
            {props.element}
          </PanelItem>
        ))}
        toolbar={
          <>
            <LabeledField label="Sort by">
              <FilterSortOrder sortOptions={sortOptions} />
            </LabeledField>
            <LabeledField label="Date range" className="ml-auto">
              <FilterDateRangePicker className="w-auto dark:border-gray-500" inputClassName="text-white/80" />
            </LabeledField>
            <ResetDateRangeButton />
            <FilterDateRangeSlider className="basis-full order-last pb-8" />
          </>
        }
        config={plotElements.map((props, idx) => (
          <PanelItem key={idx} title={props.label}>
            {props.element}
          </PanelItem>
        ))}
        PlotComponent={(props) => (
          <ActiveDateRangeResultPlot {...props} renderTitle={(props) => <ResultPlotTitle {...props} showTags />} />
        )}
        plotHeight={530}
        getKey={(result) => result.id}
      />
    </ResultFilterContextProvider>
  );
};

const ResetDateRangeButton = () => {
  const { resetActiveDateRange } = useDateFilterContext();

  return (
    <SimpleTooltip tooltipContent="Reset date range" side="bottom">
      <Button
        className="border dark:border-gray-500 dark:text-white/80 p-2"
        cva={{ size: "small2" }}
        onClick={resetActiveDateRange}
      >
        <TimerReset size={20} strokeWidth={1} />
      </Button>
    </SimpleTooltip>
  );
};
