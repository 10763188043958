export const backgroundColor = "#2E2F37";
export const lineColor = "#4C5160";
export const gridColor = "rgba(0, 0, 0, 0.5)";
export const fontColor = "#fff";

export const delimiterColor = "#666666";

//

export const referenceLineColor = "#228197";
export const referenceConfidenceBandColor = "rgba(0, 200, 229, 0.2)";

//

export const analysisLineColor = "#00c8e5";
export const analysisConfidenceBandColor = "rgba(102, 235, 255, 0.2)";

//

export const comparisonLineColorReference = "#6c01e7";

export const comparisonLineColorAnalysis = "#8C31f7";

export const comparisonConfidenceBandColor = "#5F00D250";

//

export const thresholdLineColor = "#DD4040";

export const alertColor = "#DD4040";

export const alertFill = "rgba(221, 64, 64, 0.2)";

export const alertDeep = "#720000";

export const inconclusiveTraceColor = "#00c8e5";
export const inconclusiveFillColor = "rgba(102, 235, 255, 0.2)";
export const rejectedTraceColor = "#DD4040";
export const rejectedFillColor = "rgba(221, 64, 64, 0.2)";
export const acceptedTraceColor = "#15803d";
export const acceptedFillColor = "rgba(21, 128, 61, 0.2)";

//

export const primaryBg = "#2E2F37";

export const deepBg = "#32333e";
export const cardBg = "#2b2b35";

export const confirmationDeep = "#337433";

export const redL1 = "#693131";
export const redL2 = "#794848";

export const colorPalette = [
  [59, 2, 128],
  [115, 76, 161],
  [172, 153, 195],
  [227, 228, 228],
  [75, 209, 229],
  [0, 200, 229],
];
