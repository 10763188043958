import { Check, CheckCheck, Eraser, FocusIcon } from "lucide-react";

import { Button } from "@/components/common/Button";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem } from "@/components/common/Command/Command";
import { colors } from "@/constants/style";
import { toggleValue } from "@/lib/listUtils";
import { cn } from "@/lib/utils";

type LabelGroup = {
  labels: { [key: string]: string };
  title: string;
};

type ValueGroup = {
  values: { value: string; label: string }[];
  title: string;
};

export type SelectCheckboxListProps = {
  values: string[];
  names?: { [key: string]: string } | LabelGroup[];

  selectedValues?: string[];
  setSelectedValues?: (updateValues: (currentValues: string[]) => string[]) => void;

  searchPlaceholder?: string;
  emptyPlaceholder?: string;

  containerClassName?: string;
  searchClassName?: string;
  searchInputClassName?: string;
  groupClassName?: string;
  itemClassName?: string;

  showSearch?: boolean;
  showSelectAll?: boolean;
  showRemoveAll?: boolean;
  showSelectOnlyCurrent?: boolean;
};

const selectNewValue = (value: string) => (currentValues: string[]) => {
  return toggleValue(currentValues, value);
};

export const SelectCheckboxList = ({
  values,
  names,

  selectedValues = [],
  setSelectedValues = () => {},

  searchPlaceholder = "Search...",
  emptyPlaceholder = "No options found.",

  containerClassName,
  searchClassName,
  searchInputClassName,
  groupClassName,
  itemClassName,

  showSearch = true,
  showSelectAll = false,
  showRemoveAll = false,
  showSelectOnlyCurrent = false,
}: SelectCheckboxListProps) => {
  let groups: ValueGroup[];
  if (Array.isArray(names)) {
    groups = names.map(({ title }) => ({ title, values: [] } as ValueGroup));
    values.forEach(
      (value) => {
        const idx = names.findIndex((group) => group.labels[value]);
        if (idx !== -1) {
          groups[idx].values.push({ value, label: names[idx].labels[value] });
        } else {
          console.warn(`No label found for value '${value}'. It will not be displayed.`);
        }
      },
      names.map(({ title }) => ({ title, values: [] } as ValueGroup))
    );
  } else {
    groups = [
      {
        title: "",
        values: values.map((value) => ({ value, label: names?.[value] ?? value })),
      },
    ];
  }

  return (
    <Command className={cn("dark", containerClassName)}>
      {showSearch ? (
        <CommandInput
          placeholder={searchPlaceholder}
          className={searchInputClassName}
          containerClassName={searchClassName}
        />
      ) : null}
      <CommandEmpty>{emptyPlaceholder}</CommandEmpty>
      <CommandGroup className={cn("p-0", groupClassName)}>
        {showSelectAll ? (
          <CommandItem
            className={itemClassName}
            onSelect={() => {
              setSelectedValues((_) => groups.flatMap((group) => group.values.map(({ value }) => value)));
            }}
          >
            <CheckCheck
              // color={colors.highlightDeep}
              className={cn("mr-2 h-4 w-4")}
            />
            Select All
          </CommandItem>
        ) : null}

        {showRemoveAll ? (
          <CommandItem
            className={itemClassName}
            onSelect={() => {
              setSelectedValues((_) => []);
            }}
          >
            <Eraser
              // color={colors.highlightDeep}
              className={cn("mr-2 h-4 w-4")}
            />
            Remove All
          </CommandItem>
        ) : null}
      </CommandGroup>

      <div className={cn("flex flex-col gap-3", groupClassName)}>
        {groups.map(
          ({ title, values }) =>
            values.length > 0 && (
              <CommandGroup
                key={title}
                className="py-0"
                heading={title && <span className="text-xs font-normal uppercase">{title}</span>}
              >
                {values.map(({ label, value }) => (
                  <CommandItem
                    key={value}
                    className={cn("flex gap-2", itemClassName)}
                    value={value}
                    onSelect={() => {
                      setSelectedValues(selectNewValue(value));
                    }}
                    title={value}
                  >
                    <Check
                      color={colors.highlightDeep}
                      className={cn("h-4 w-4 shrink-0", selectedValues.includes(value) ? "opacity-100" : "opacity-0")}
                    />
                    <span className="truncate">{label}</span>
                    {showSelectOnlyCurrent ? (
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedValues((_) => [value]);
                        }}
                        tabIndex={-1}
                        className="ml-auto p-0"
                        cva={{ intent: "transparent" }}
                        title="Show only this item"
                        preLabel={
                          <FocusIcon
                            className={cn("mr-2 h-5 w-5", "hidden group-hover:block hover:text-highlightDeep")}
                          />
                        }
                      />
                    ) : null}
                  </CommandItem>
                ))}
              </CommandGroup>
            )
        )}
      </div>
    </Command>
  );
};
