import { useApolloClient, useMutation, useSuspenseQuery } from "@apollo/client";
import { AlertTriangleIcon } from "lucide-react";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Text } from "@/DesignSystem/basic/Text/Text";
import { Input } from "@/DesignSystem/shadcn/Input";
import {
  EditEvaluationModelInput,
  EvaluationPerformanceMetricConfigInput,
  FragmentType,
  ProductType,
  gql,
  useFragment,
} from "@/apis/nannyml";
import { DataSourceOverview } from "@/components/DataSource";
import { confirm } from "@/components/Dialog";
import { LabeledField } from "@/components/LabeledField";
import { Button } from "@/components/common/Button";
import { useToast } from "@/components/common/Toast/useToast";
import { EvaluationMetricConfig } from "@/components/evaluation/EvaluationMetricConfig";
import { nrSignificantDigits } from "@/formatters/bayesian";
import { evaluationHypothesisLabels } from "@/formatters/evaluation";
import { problemTypeLabels } from "@/formatters/models";
import { performanceMetricLabels } from "@/formatters/monitoring";
import { useEvaluationModelId, evaluationModelMetadataFragment, useStartEvaluationRun } from "@/hooks/evaluation";
import { omitRecursively } from "@/lib/objUtils";
import { cn } from "@/lib/utils";

type StagedChanges = Partial<EditEvaluationModelInput>;
type StageChangeFn = (change: Partial<EditEvaluationModelInput>) => void;

const evaluationModelMetricConfigFragment = gql(/* GraphQL */ `
  fragment EvaluationModelMetricConfigFragment on EvaluationModel {
    hypothesis
    kpm
    config {
      metrics {
        metric
        enabled
        ropeLowerBound
        ropeUpperBound
        hdiWidth
      }
    }
  }
`);

const evaluationModelDataOverviewFragment = gql(/* GraphQL */ `
  fragment EvaluationModelDataOverviewFragment on EvaluationModel {
    id
    referenceDataSource {
      ...DataSourceDetails
      nrRows
    }
    evaluationDataSource {
      ...DataSourceDetails
      nrRows
    }
  }
`);

const evaluationModelConfigQuery = gql(/* GraphQL */ `
  query EvaluationModelConfig($modelId: Int!) {
    evaluation_model(id: $modelId) {
      ...EvaluationModelMetadata
      ...EvaluationModelMetricConfigFragment
      ...EvaluationModelDataOverviewFragment
    }
  }
`);

const editEvaluationModelMutation = gql(/* GraphQL */ `
  mutation EditEvaluationModel($input: EditEvaluationModelInput!) {
    edit_evaluation_model(input: $input) {
      ...EvaluationModelMetadata
      ...EvaluationModelMetricConfigFragment
    }
  }
`);

const deleteEvaluationModelMutation = gql(/* GraphQL */ `
  mutation DeleteEvaluationModel($modelId: Int!) {
    delete_evaluation_model(evaluationModelId: $modelId) {
      id
    }
  }
`);

export const EvaluationModelSettings = () => {
  const client = useApolloClient();
  const modelId = useEvaluationModelId();
  const { resolveWithToast } = useToast();
  const [stagedChanges, setStagedChanges] = useState<EditEvaluationModelInput>({ modelId });
  const { data } = useSuspenseQuery(evaluationModelConfigQuery, { variables: { modelId } });
  const [editEvaluationModel, { loading }] = useMutation(editEvaluationModelMutation, {
    onCompleted: () => {
      setStagedChanges({ modelId });
      client.cache.evict({ id: `EvaluationModel:${modelId}`, fieldName: "results" });
    },
  });

  const model = data.evaluation_model;
  if (!model) {
    throw new Error(`Model '${modelId}' not found`);
  }

  const stageChange = useCallback(
    (change: Partial<EditEvaluationModelInput>) => setStagedChanges((prev) => ({ ...prev, ...change })),
    [setStagedChanges]
  );

  return (
    <div className="p-4 flex flex-col 2xl:flex-row gap-8">
      <form
        className="flex flex-col gap-8 items-center"
        onSubmit={(e) => {
          e.preventDefault();

          const promise = editEvaluationModel({ variables: { input: stagedChanges } });
          resolveWithToast(promise, {
            title: "Changing model settings...",
            successDescription: "✅ Model settings changed",
            errorDescription: "❌ Failed to change model settings",
          });
        }}
      >
        <Section title="Model settings">
          <ModelSettings value={model} stageChange={stageChange} stagedChanges={stagedChanges} />
        </Section>
        <Section title="Metrics">
          <MetricSettings value={model} stageChange={stageChange} stagedChanges={stagedChanges} />
        </Section>
        <Button
          cva={{ intent: "primary" }}
          type="submit"
          disabled={Object.values(stagedChanges).length <= 1 || loading}
        >
          Save changes
        </Button>
      </form>
      <div className="flex flex-col gap-8">
        <Section title="Data">
          <DataOverview model={model} />
        </Section>
        <Section title="Danger zone" className="[&>p]:text-alert">
          <DangerZone model={model} />
        </Section>
      </div>
    </div>
  );
};

const Section = ({ children, className, title }: { children: React.ReactNode; className?: string; title: string }) => (
  <div className={cn("w-full space-y-4", className)}>
    <Text className="pb-1 border-b border-gray-600" size="xLarge" stroke="boldLight">
      {title}
    </Text>
    {children}
  </div>
);

const ModelSettings = ({
  value,
  stageChange,
  stagedChanges,
}: {
  value: FragmentType<typeof evaluationModelMetadataFragment>;
  stageChange: StageChangeFn;
  stagedChanges: StagedChanges;
}) => {
  const model = useFragment(evaluationModelMetadataFragment, value);

  return (
    <div className="grid grid-cols-3 gap-4">
      <LabeledField label="Model name" className="col-span-full">
        <Input
          type="text"
          value={stagedChanges?.name ?? model.name}
          onChange={(e) => stageChange({ name: e.target.value })}
        />
      </LabeledField>
      <LabeledField label="Problem type">
        {problemTypeLabels[stagedChanges.problemType ?? model.problemType]}
      </LabeledField>
      <LabeledField label="KPM">{performanceMetricLabels[stagedChanges.kpm ?? model.kpm]}</LabeledField>
      <LabeledField label="Created at">{new Date(model.createdAt).toLocaleString()}</LabeledField>
      <LabeledField label="Evaluation hypothesis" className="col-span-2">
        {evaluationHypothesisLabels[stagedChanges.hypothesis ?? model.hypothesis]}
      </LabeledField>
      <LabeledField label="Classification threshold">
        {(stagedChanges.classificationThreshold ?? model.classificationThreshold).toPrecision(nrSignificantDigits)}
      </LabeledField>
    </div>
  );
};

const MetricSettings = ({
  value,
  stageChange,
  stagedChanges,
}: {
  value: FragmentType<typeof evaluationModelMetricConfigFragment>;
  stageChange: StageChangeFn;
  stagedChanges: StagedChanges;
}) => {
  const model = useFragment(evaluationModelMetricConfigFragment, value);
  return (
    <EvaluationMetricConfig
      value={{
        hypothesis: stagedChanges.hypothesis ?? model.hypothesis,
        kpm: stagedChanges.kpm ?? model.kpm,
        metrics:
          (stagedChanges.runtimeConfig?.metrics as Array<EvaluationPerformanceMetricConfigInput>) ??
          omitRecursively(model.config.metrics, "__typename"),
      }}
      onChange={({ kpm, metrics }) => {
        if (kpm) {
          stageChange({ kpm });
        }
        if (metrics) {
          stageChange({ runtimeConfig: { metrics } });
        }
      }}
    />
  );
};

const DataOverview = (props: { model: FragmentType<typeof evaluationModelDataOverviewFragment> }) => {
  const model = useFragment(evaluationModelDataOverviewFragment, props.model);

  return <DataSourceOverview dataSources={[model.referenceDataSource, model.evaluationDataSource]} />;
};

const DangerZone = (props: { model: FragmentType<typeof evaluationModelMetadataFragment> }) => {
  const navigate = useNavigate();
  const { resolveWithToast } = useToast();
  const [deleteModel, { loading }] = useMutation(deleteEvaluationModelMutation);
  const model = useFragment(evaluationModelMetadataFragment, props.model);

  const deleteModelWithToast = () => {
    const promise = deleteModel({ variables: { modelId: model.id } }).then(() => navigate("/evaluation"));
    resolveWithToast(promise, {
      title: "Deleting model...",
      successDescription: "✅ Model deleted",
      errorDescription: "❌ Failed to delete model",
      retry: deleteModelWithToast,
    });
  };

  const onDeleteModel = () => {
    confirm({
      title: "Delete evaluation model",
      message: <DeleteModelDialog modelName={model.name} />,
      confirmLabel: "Delete",
      cancelIntent: "primary",
      confirmIntent: "secondary",
    }).then((confirmed) => {
      if (confirmed) {
        deleteModelWithToast();
      }
    });
  };

  return (
    <div>
      <p className="text-gray-400 text-sm mt-2">
        This section allows you to make changes to the model that could break things. Make sure you know what you're
        doing before applying changes.
      </p>
      <div className="flex justify-between mt-4 items-center gap-4">
        <div className="flex flex-col">
          <p className="font-bold">Delete model</p>
          <p className="text-sm text-gray-400">
            Deleting a model will permanently delete all its results, data and settings. This cannot be undone.
          </p>
        </div>
        <Button
          label={"Delete model"}
          cva={{ intent: "reject" }}
          className="p-2 justify-center whitespace-nowrap h-fit"
          onClick={onDeleteModel}
          disabled={loading}
        />
      </div>
    </div>
  );
};

const DeleteModelDialog = ({ modelName }: { modelName: string }) => (
  <div className="flex mb-3 items-center">
    <AlertTriangleIcon className="inline-block mr-5 text-warningPale" size={64} />
    <div className="flex flex-col gap-3">
      <p>
        Deleting an evaluation model will <b>permanently delete</b> all its results, data and settings. This cannot be
        undone.
      </p>
      <p>Are you sure you want to delete model '{modelName}'?</p>
    </div>
  </div>
);
