import _ from "lodash";
import { SettingsIcon } from "lucide-react";
import { useState } from "react";

import { Input } from "@/DesignSystem/shadcn/Input";
import {
  BusinessValueMetricConfigInput,
  FragmentType,
  PerformanceMetric,
  PerformanceMetricConfigInput,
  PerformanceType,
  gql,
  useFragment,
} from "@/apis/nannyml";
import { Dialog, DialogContent, DialogTrigger } from "@/components/Dialog";
import { LabeledField } from "@/components/LabeledField";
import { RadioGroup, RadioGroupItem } from "@/components/RadioGroup";
import { Select, SelectItem } from "@/components/Select";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/Table";
import { Button } from "@/components/common/Button";
import { toast } from "@/components/common/Toast/useToast";
import { InformationModalChip } from "@/components/dashboard/InformationModal/InformationModalChip";
import { performanceMetricLabels, performanceTypeLabels } from "@/formatters/monitoring";
import { parseNullableFloat } from "@/lib/numbersUtils";
import { cn } from "@/lib/utils";

import { MetricEnableToggle } from "./MetricEnableToggle";
import { ThresholdConfigCells, ThresholdConfigHeaderCells } from "./ThresholdConfig";
import { PerformanceConfigComponentProps, SegmentPerformanceConfigComponentProps } from "./types";

const performanceRuntimeConfigFragment = gql(/* GraphQL */ `
  fragment PerformanceRuntimeConfig on RuntimeConfig {
    performanceTypes {
      type
      isSupported
    }
    performanceMetrics {
      metric
      realized {
        isSupported
        ...IsSupportedConfig
      }
      estimated {
        isSupported
        ...IsSupportedConfig
      }
      lowerValueLimit
      upperValueLimit
    }
  }
`);

const usePerformanceEdit = ({
  config: configFragment,
  value: { performanceMetrics },
  kpm,
  onValueChange,
  onKpmChange,
}: PerformanceConfigComponentProps<FragmentType<typeof performanceRuntimeConfigFragment>>) => {
  const config = useFragment(performanceRuntimeConfigFragment, configFragment);
  const configMetrics = _.keyBy(config.performanceMetrics, "metric");

  const onMetricChange = (metric: PerformanceMetric, change: Partial<PerformanceMetricConfigInput>) => {
    const metricConfig = { ...performanceMetrics.find((m) => m.metric === metric), ...change };
    // When completely disabling metric
    if (!metricConfig.enabledEstimated && !metricConfig.enabledRealized) {
      // Make sure at least one metric is enabled
      const enabledMetric = performanceMetrics.find(
        (m) => m.metric !== metric && (m.enabledEstimated || m.enabledRealized)
      );
      if (!enabledMetric) {
        toast({
          title: "Cannot disable metric",
          description: "At least one metric must be enabled",
          variant: "error",
        });
        return;
      }

      // Switch KPM to another metric
      if (metric === kpm.metric) {
        onKpmChange({ metric: enabledMetric.metric });
      }
    }
    onValueChange({
      performanceMetrics: (performanceMetrics ?? []).map((m) => (m.metric === metric ? { ...m, ...change } : m)),
    });
  };

  return {
    configMetrics,
    onMetricChange,
    onKpmChange: (metric: string) => {
      const metricValue = performanceMetrics.find((m) => m.metric === metric);
      if (!metricValue?.enabledEstimated && !metricValue?.enabledRealized) {
        onMetricChange(metric as PerformanceMetric, {
          enabledEstimated: configMetrics[metric as PerformanceMetric].estimated.isSupported,
          enabledRealized: configMetrics[metric as PerformanceMetric].realized.isSupported,
        });
      }
      onKpmChange({ metric: metric as PerformanceMetric });
    },
  };
};

export const PerformanceConfig = (
  props: PerformanceConfigComponentProps<FragmentType<typeof performanceRuntimeConfigFragment>>
) => {
  const { configMetrics, onMetricChange, onKpmChange } = usePerformanceEdit(props);

  return (
    <RadioGroup
      className={cn("flex flex-col gap-4", props.className)}
      value={props.kpm?.metric}
      onValueChange={onKpmChange}
    >
      <PerformanceEstimatorConfig {...props} />
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Metric</TableHead>
            <TableHead className="whitespace-nowrap">
              KPM
              <InformationModalChip infoName="Key performance metric" className="p-0 mr-0 inline" />
            </TableHead>
            <TableHead>Realized performance</TableHead>
            <TableHead>Estimated performance</TableHead>
            <ThresholdConfigHeaderCells />
            <TableHead />
          </TableRow>
        </TableHeader>
        <TableBody>
          {props.value.performanceMetrics.map((metric) => (
            <TableRow key={metric.metric}>
              <TableCell>{performanceMetricLabels[metric.metric]}</TableCell>
              <TableCell>
                <KpmSelect metric={metric.metric} />
              </TableCell>
              <TableCell>
                <MetricEnableToggle
                  config={configMetrics[metric.metric].realized}
                  value={metric.enabledRealized ?? false}
                  onValueChange={(enabledRealized) => onMetricChange(metric.metric, { enabledRealized })}
                />
              </TableCell>
              <TableCell>
                <MetricEnableToggle
                  config={configMetrics[metric.metric].estimated}
                  value={metric.enabledEstimated ?? false}
                  onValueChange={(enabledEstimated) => onMetricChange(metric.metric, { enabledEstimated })}
                />
              </TableCell>
              <ThresholdConfigCells
                config={configMetrics[metric.metric]}
                value={metric}
                disabled={!metric.enabledEstimated && !metric.enabledRealized}
                onValueChange={(change) => onMetricChange(metric.metric, change)}
              />
              <TableCell>
                <MetricConfigButton
                  config={metric}
                  onConfigChange={(config) => onMetricChange(metric.metric, config)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </RadioGroup>
  );
};

export const PerformanceEstimatorConfig = ({
  config: configFragment,
  value: { performanceTypes },
  onValueChange,
  className,
}: Pick<
  PerformanceConfigComponentProps<FragmentType<typeof performanceRuntimeConfigFragment>>,
  "config" | "value" | "onValueChange" | "className"
>) => {
  const config = useFragment(performanceRuntimeConfigFragment, configFragment);
  const estimatorOptions =
    config.performanceTypes.filter((t) => t.type !== PerformanceType.Realized && t.isSupported).map((t) => t.type) ??
    [];
  const estimator = performanceTypes.find((t) => t.enabled && estimatorOptions.includes(t.type))?.type;
  const onEstimatorChange = (estimator: PerformanceType) => {
    onValueChange({
      performanceTypes: (performanceTypes ?? []).map((t) => ({
        ...t,
        enabled: t.type === estimator ? true : t.type === PerformanceType.Realized ? t.enabled : false,
      })),
    });
  };

  if (estimatorOptions.length <= 1) {
    return null;
  }

  return (
    <LabeledField
      label={
        <div className="flex items-end">
          Performance estimation method{" "}
          <InformationModalChip infoName="Performance analysis" className="text-current ml-1" />
        </div>
      }
      className={cn("w-fit", className)}
    >
      <Select value={estimator} onValueChange={onEstimatorChange} className="min-w-[100px]">
        {estimatorOptions.map((type) => (
          <SelectItem key={type} value={type}>
            {performanceTypeLabels[type]}
          </SelectItem>
        ))}
      </Select>
    </LabeledField>
  );
};

export const PerformanceEnableConfig = (
  props: PerformanceConfigComponentProps<FragmentType<typeof performanceRuntimeConfigFragment>>
) => {
  const { configMetrics, onMetricChange, onKpmChange } = usePerformanceEdit(props);
  return (
    <RadioGroup value={props.kpm?.metric} onValueChange={onKpmChange} asChild>
      <Table className={cn("table", props.className)}>
        <TableHeader>
          <TableRow>
            <TableHead>Metric</TableHead>
            <TableHead className="whitespace-nowrap">
              KPM
              <InformationModalChip infoName="Key performance metric" className="p-0 mr-0 inline" />
            </TableHead>
            <TableHead className="whitespace-nowrap">Realized performance</TableHead>
            <TableHead className="whitespace-nowrap">Estimated performance</TableHead>
            <TableHead className="w-full"></TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {props.value.performanceMetrics.map((metric) => (
            <TableRow key={metric.metric}>
              <TableCell className="whitespace-nowrap">{performanceMetricLabels[metric.metric]}</TableCell>
              <TableCell>
                <KpmSelect metric={metric.metric} />
              </TableCell>
              <TableCell>
                <MetricEnableToggle
                  config={configMetrics[metric.metric].realized}
                  value={metric.enabledRealized ?? false}
                  onValueChange={(enabledRealized) => onMetricChange(metric.metric, { enabledRealized })}
                />
              </TableCell>
              <TableCell>
                <MetricEnableToggle
                  config={configMetrics[metric.metric].estimated}
                  value={metric.enabledEstimated ?? false}
                  onValueChange={(enabledEstimated) => onMetricChange(metric.metric, { enabledEstimated })}
                />
              </TableCell>
              <TableCell>
                <MetricConfigButton
                  config={metric}
                  onConfigChange={(config) => onMetricChange(metric.metric, config)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </RadioGroup>
  );
};

export const PerformanceThresholdConfig = ({
  segmentId,
  ...props
}: SegmentPerformanceConfigComponentProps<FragmentType<typeof performanceRuntimeConfigFragment>>) => {
  const { configMetrics, onMetricChange } = usePerformanceEdit(props);
  return (
    <Table className={props.className}>
      <TableHeader>
        <TableRow>
          <TableHead>Metric</TableHead>
          <TableHead className="whitespace-nowrap">Threshold type</TableHead>
          <TableHead className="whitespace-nowrap">Threshold value</TableHead>
          <TableHead className="w-full" />
        </TableRow>
      </TableHeader>
      <TableBody>
        {props.value.performanceMetrics.map((value) => (
          <TableRow key={value.metric}>
            <TableCell className="whitespace-nowrap">{performanceMetricLabels[value.metric]}</TableCell>
            <ThresholdConfigCells
              config={configMetrics[value.metric]}
              value={value}
              segmentId={segmentId}
              onValueChange={(change) => onMetricChange(value.metric, change)}
              disabled={!value.enabledEstimated && !value.enabledRealized}
            />
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const KpmSelect = ({ metric }: { metric: PerformanceMetric }) => (
  <div className="flex justify-center items-center">
    <RadioGroupItem
      value={metric}
      disabled={metric === PerformanceMetric.ConfusionMatrix}
      title={
        metric === PerformanceMetric.ConfusionMatrix
          ? "Confusion matrix consists of multiple elements and cannot be selected as KPM"
          : undefined
      }
    />
  </div>
);

const MetricConfigButton = ({
  config,
  onConfigChange,
}: {
  config: PerformanceMetricConfigInput;
  onConfigChange: (config: Partial<PerformanceMetricConfigInput>) => void;
}) => {
  if (config.metric !== PerformanceMetric.BusinessValue) {
    return null;
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button
          cva={{ size: "mediumLong" }}
          className="flex items-center gap-2"
          disabled={!config.enabledEstimated && !config.enabledRealized}
        >
          <SettingsIcon size={16} />
          Configure
        </Button>
      </DialogTrigger>
      <DialogContent className="text-pale max-w-full w-fit overflow-auto">
        <BusinessValueConfig
          value={config.businessValue!}
          onValueChange={(businessValue) => onConfigChange({ businessValue })}
        />
      </DialogContent>
    </Dialog>
  );
};

const BusinessValueConfig = ({
  value,
  onValueChange,
}: {
  value: BusinessValueMetricConfigInput;
  onValueChange: (value: BusinessValueMetricConfigInput) => void;
}) => {
  const [weights, setWeights] = useState<Record<string, string>>({
    trueNegativeWeight: value.trueNegativeWeight.toString(),
    falsePositiveWeight: value.falsePositiveWeight.toString(),
    falseNegativeWeight: value.falseNegativeWeight.toString(),
    truePositiveWeight: value.truePositiveWeight.toString(),
  });

  const onWeightChange = (key: string, input: string) => {
    const weight = parseNullableFloat(input);
    setWeights((weights) => ({ ...weights, [key]: input }));
    if (weight !== null) {
      onValueChange({ ...value, [key]: weight });
    }
  };
  return (
    <div className="flex flex-col">
      <h3 className="font-bold text-lg">Business value configuration</h3>
      <span className="text-sm text-gray-400">
        The business value metric is calculated as a weighted sum of the confusion matrix elements.
      </span>
      <div className="flex items-center gap-2 [&>span]:mt-4 mt-6">
        <LabeledField label="True negative weight">
          <Input
            required
            placeholder="weight"
            value={weights.trueNegativeWeight ?? ""}
            onChange={(e) => onWeightChange("trueNegativeWeight", e.target.value)}
          />
        </LabeledField>
        <span>*</span>
        <span>TN</span>
        <span>+</span>
        <LabeledField label="False positive weight">
          <Input
            required
            placeholder="weight"
            value={weights.falsePositiveWeight ?? ""}
            onChange={(e) => onWeightChange("falsePositiveWeight", e.target.value)}
          />
        </LabeledField>
        <span>*</span>
        <span>FP</span>
        <span>+</span>
        <LabeledField label="False negative weight">
          <Input
            required
            placeholder="weight"
            value={weights.falseNegativeWeight ?? ""}
            onChange={(e) => onWeightChange("trueNegativeWeight", e.target.value)}
          />
        </LabeledField>
        <span>*</span>
        <span>FN</span>
        <span>+</span>
        <LabeledField label="True positive weight">
          <Input
            required
            placeholder="weight"
            value={weights.truePositiveWeight ?? ""}
            onChange={(e) => onWeightChange("truePositiveWeight", e.target.value)}
          />
        </LabeledField>
        <span>*</span>
        <span>TP</span>
      </div>
    </div>
  );
};
