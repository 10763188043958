import _ from "lodash";

import { SelectCheckboxList, SelectCheckboxListProps } from "@/components/dashboard/SelectCheckbox/SelectCheckboxList";

import { useFilter, useFilterConfig, useFilterContext } from "./Filters.hooks";

export type FilterMetricsItemType = {
  metricName: string;
};

export type FilterMetricsConfigType = {
  metrics?: string[];
};

export const FilterMetrics = ({ metricLabels }: { metricLabels: SelectCheckboxListProps["names"] }) => {
  const [{ metrics }, setFilterConfig] = useFilterConfig<FilterMetricsConfigType>();
  const { items: results } = useFilterContext<FilterMetricsItemType, FilterMetricsConfigType>();

  useFilter<FilterMetricsItemType, FilterMetricsConfigType>((resultGroups, { metrics }) => {
    if (!metrics?.length) {
      return resultGroups;
    }

    return resultGroups.filter((results) =>
      results.some((result) => metrics.includes(result.metricName.toUpperCase()))
    );
  });

  const setSelectedMetrics = (updateMetrics: (currentMetrics: string[]) => string[]) => {
    setFilterConfig({ metrics: updateMetrics(metrics ?? []) });
  };

  return (
    <SelectCheckboxList
      values={_.uniq(results.map((result) => result.metricName.toUpperCase()))}
      names={metricLabels}
      selectedValues={metrics}
      setSelectedValues={setSelectedMetrics}
      showSearch={false}
      showSelectOnlyCurrent={true}
      emptyPlaceholder="No metrics found."
    />
  );
};
