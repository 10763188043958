import { cva, VariantProps } from "class-variance-authority";
import React, { ReactNode } from "react";

import { cn } from "@/lib/utils";

export const buttonStyle = cva(["flex items-center gap-3 font-semibold rounded w-fit"], {
  variants: {
    intent: {
      primary: "bg-highlightDeep/50 text-pale hover:bg-highlightDeep/75",
      confirm: "text-pale bg-greenBase hover:bg-greenL1",
      contrast: "bg-contrastDeep text-pale hover:bg-contrastDeep/75",
      reject: "text-pale bg-redL1 hover:bg-redL2",
      secondary: "bg-dark text-pale hover:bg-slate-900",
      optional: "bg-optional/75 text-pale hover:bg-optional",
      action: "bg-transparent text-highlightDeep border-highlightDeep",
      subtle: "dark:bg-transparent bg-transparent text-pale underline",
      chip: "bg-dark hover:bg-dark/50 text-pale border border-placeholder rounded-4",
      transparent: "bg-transparent",
      disabled: ["bg-gray-200", "text-gray-800", "border-transparent"],
      disabledOpaque: ["bg-disabledGray", "text-placeholder", "border-transparent"],
      icon: [
        "bg-transparent text-pale hover:bg-gray-600 rounded-full",
        "disabled:opacity-50 disabled:hover:bg-transparent",
        "aria-pressed:text-highlightDeep",
      ],
    },
    size: {
      small: ["text-sm", "py-1", "px-2", "gap-2"],
      small2: ["text-base", "py-1.5", "px-2", "gap-2"],
      medium: ["text-base font-bold", "py-3", "px-5"],
      mediumLong: ["text-base font-bold", "py-2", "px-3"],
      large: ["text-lg font-bold", "py-4", "px-8"],
      chip: ["text-xs", "p-1", "gap-1"],
    },

    border: {
      none: "border-0",
      thin: "border border-placeholder",
    },
  },
  compoundVariants: [
    {
      border: "thin",
      intent: "action",
      className: "border-highlightDeep hover:bg-highlightDeep/10",
    },
  ],
  defaultVariants: {
    intent: "secondary",
    size: "medium",
    border: "none",
  },
});

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  label?: string | JSX.Element;
  preLabel?: JSX.Element | null;
  posLabel?: ReactNode;
  cva?: VariantProps<typeof buttonStyle>;
  disabledStyle?: VariantProps<typeof buttonStyle>["intent"];
};

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ label, preLabel, posLabel, disabled, disabledStyle, ...props }: ButtonProps, ref) => {
    const cva: VariantProps<typeof buttonStyle> = {
      ...props.cva,
      intent: disabled ? disabledStyle ?? "disabledOpaque" : props.cva?.intent,
    };

    return (
      <button type="button" {...props} ref={ref} disabled={disabled} className={cn(buttonStyle(cva), props.className)}>
        {Boolean(props.children) ? (
          props.children
        ) : (
          <>
            {preLabel ? preLabel : null}
            {label}
            {posLabel ? posLabel : null}
          </>
        )}
      </button>
    );
  }
);

Button.displayName = "Button";
