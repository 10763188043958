import { createColumnHelper } from "@tanstack/react-table";
import { Check, RefreshCw, X } from "lucide-react";
import { ReactNode } from "react";
import { Link } from "react-router-dom";

import { Text } from "@/DesignSystem/basic/Text/Text";
import { SimpleTooltip } from "@/DesignSystem/nanny/SimpleTooltip/SimpleTooltip";
import { RunStatusIcon } from "@/components/RunStatus";
import { ModelResultsViewsOptions } from "@/domains/model/modelOptions";
import { ModelOverviewColumn } from "@/domains/model/modelOverview";
import { MonitorResult } from "@/domains/monitorResults/monitorResults.types";
import { getNameOfPerformanceMetric } from "@/formatters/performance/performanceTexts";
import {
  getNameWithColumn,
  NameElements,
  getMetricName,
  getMetricAndComponentName,
} from "@/formatters/results/resultsStrings";
import { cn } from "@/lib/utils";

const columnHelper = createColumnHelper<ModelOverviewColumn>();

export const getModelPlotPath = (modelId: number | string, section: string) =>
  `/monitoring/model/${modelId}/${section}`;

const getAlertClassName = (hasAlert?: boolean) => {
  return hasAlert === undefined ? "" : hasAlert ? "text-alert" : "text-confirmationPale";
};

const MidLevelHeader = ({ children, className }: { children?: ReactNode; className?: string }) => {
  return <span className={cn("inline-block w-full text-center font-normal", className)}>{children}</span>;
};

const TopLevelHeader = ({ children, className }: { children?: ReactNode; className?: string }) => {
  return (
    <span className={cn("inline-block w-full text-center font-bold whitespace-nowrap", className)}>{children}</span>
  );
};

const AlertStatusIcon = ({ hasAlert }: { hasAlert: boolean }) => {
  return (
    <div className={cn("w-full h-full flex justify-center items-center", getAlertClassName(hasAlert))}>
      <div className={"border border-current rounded-full p-[2px]"}>
        {hasAlert ? <X size={14} /> : <Check size={14} />}
      </div>
    </div>
  );
};

const AlertIconTooltip = ({
  hasAlert,
  resultsInAlert,
  getResultName,
  emptyResultsMessage,
  tooltipTitle = `Results with "summary" tag`,
  linkTo,
  disabled,
}: {
  hasAlert: boolean;
  resultsInAlert?: MonitorResult[];
  getResultName?: (nameElements: NameElements) => string[] | readonly [string, string];
  emptyResultsMessage?: string;
  tooltipTitle?: string;
  linkTo?: string;
  disabled?: boolean;
}) => {
  const hasResults = resultsInAlert?.length;

  const link = !disabled && linkTo ? linkTo : "#";

  const tooltipContent = hasResults ? (
    <div className={"fcol gap-2"}>
      {resultsInAlert?.map((result, index) => {
        const metricName = getResultName
          ? getResultName({
              ...result.keys,
              analysisType: result.keys?.analysis,
              calculatorType: result.keys?.calculatorType,
            }).join(" - ")
          : result.keys?.metric;

        return (
          <div key={index} className={"flex gap-2 items-center"}>
            <span>
              <span className="text-gray-400">
                {result.keys?.segment
                  ? `${result.keys.segment.segmentColumnName}: ${result.keys.segment.segment}`
                  : "All data"}
              </span>
              {`, ${metricName} = `}
            </span>

            <span className={getAlertClassName(result.lastDataPoint?.hasAlert)}>{`${
              result.lastDataPoint?.value?.toFixed(2) ?? "No result available"
            }`}</span>
          </div>
        );
      })}
    </div>
  ) : (
    <div>{emptyResultsMessage ?? "No results found."}</div>
  );

  return (
    <div className="h-full w-full flex justify-center">
      <SimpleTooltip
        side={"right"}
        tooltipContent={
          <div className={"fcol gap-4 px-4 py-4"}>
            {tooltipTitle ? <Text stroke={"bold"}>{tooltipTitle}</Text> : null}
            {tooltipContent}
          </div>
        }
      >
        <Link to={link} className={cn("block px-2 h-full", disabled ? "opacity-0 pointer-events-none" : "")}>
          {hasResults ? <AlertStatusIcon hasAlert={hasAlert} /> : <div className={cn("w-2 h-2")}>-</div>}
        </Link>
      </SimpleTooltip>
    </div>
  );
};

export const ModelOverviewColumns = [
  columnHelper.group({
    id: "model name",
    meta: {
      className: "border-r border-disabledGray w-full",
    },

    // Model name
    header: () => <TopLevelHeader>Model name</TopLevelHeader>,
    columns: [
      columnHelper.accessor("modelName", {
        header: () => <MidLevelHeader />,
        meta: {
          className: "border-r border-disabledGray",
        },
        cell: ({ row }) => {
          const modelId = row.original.modelId;
          const modelName = row.getValue("modelName");

          return (
            <div className="px-4 py-2">
              <Link to={`/monitoring/model/${modelId}`} className="font-bold text-pale underline">
                {`${modelId} - ${modelName}`}
              </Link>
            </div>
          );
        },
      }),
    ],
  }),

  // Main performance metric
  columnHelper.group({
    id: "main performance metric",
    header: () => <TopLevelHeader>Main performance metric</TopLevelHeader>,
    meta: {
      className: "border-r border-disabledGray",
    },
    columns: [
      columnHelper.accessor("performanceMainMetricKeys", {
        header: () => <MidLevelHeader className="text-right">Name</MidLevelHeader>,
        meta: {
          className: "pr-0",
        },
        cell: ({ row }) => {
          const value = row.original.performanceMainMetricKeys;

          return (
            <Link to={getModelPlotPath(row.original.modelId, ModelResultsViewsOptions.Performance)}>
              <span className={"flex justify-end pl-1 whitespace-nowrap"}>
                {getMetricAndComponentName(value?.metric ?? "-", value?.componentName, value?.analysisType, true)}
              </span>
            </Link>
          );
        },
      }),
      columnHelper.accessor("performanceMainMetricRealizedValue", {
        header: () => <MidLevelHeader>Realized</MidLevelHeader>,
        meta: {
          className: "pr-0",
        },
        cell: ({ row, getValue }) => {
          const hasAlert = row.original.performanceMainMetricRealizedAlerts;

          return (
            <Link to={getModelPlotPath(row.original.modelId, ModelResultsViewsOptions.Performance)}>
              <span className={cn("flex justify-center", getAlertClassName(hasAlert))}>{getValue() ?? "-"}</span>
            </Link>
          );
        },
      }),
      columnHelper.accessor("performanceMainMetricEstimatedValue", {
        header: () => <MidLevelHeader>Estimated</MidLevelHeader>,
        meta: {
          className: "border-r border-disabledGray",
        },
        cell: ({ row, getValue }) => {
          const hasAlert = row.original.performanceMainMetricEstimatedAlerts;
          return (
            <Link
              to={getModelPlotPath(row.original.modelId, ModelResultsViewsOptions.Performance)}
              className={cn("flex items-center h-full")}
            >
              <span className={cn("flex flex-grow justify-center", getAlertClassName(hasAlert))}>
                {getValue() ?? "-"}
              </span>
            </Link>
          );
        },
      }),
    ],
  }),

  columnHelper.group({
    id: "summary performance metrics",
    header: () => <TopLevelHeader>Summary performance metrics</TopLevelHeader>,
    meta: {
      className: "border-r border-disabledGray",
    },
    columns: [
      columnHelper.accessor("performanceOtherMetricsRealizedAlerts", {
        header: () => <MidLevelHeader>Realized</MidLevelHeader>,
        cell: ({ getValue, row }) => (
          <AlertIconTooltip
            hasAlert={getValue()}
            resultsInAlert={row.original.performanceOtherMetricsRealizedResults}
            getResultName={getNameOfPerformanceMetric}
            linkTo={getModelPlotPath(row.original.modelId, ModelResultsViewsOptions.Performance)}
          />
        ),
      }),
      columnHelper.accessor("performanceOtherMetricsEstimatedAlerts", {
        header: () => <MidLevelHeader>Estimated</MidLevelHeader>,
        meta: {
          className: "border-r border-disabledGray",
        },
        cell: ({ row, getValue }) => (
          // todo: Add parameter to filter by alerts and ignore stored filters
          <AlertIconTooltip
            hasAlert={getValue()}
            resultsInAlert={row.original.performanceOtherMetricsEstimatedResults}
            getResultName={getNameOfPerformanceMetric}
            linkTo={getModelPlotPath(row.original.modelId, ModelResultsViewsOptions.Performance)}
          />
        ),
      }),
    ],
  }),

  columnHelper.group({
    id: "conceptShiftAlerts",
    header: () => <TopLevelHeader>Concept shift</TopLevelHeader>,
    columns: [
      columnHelper.accessor("conceptDriftAlerts", {
        header: () => <MidLevelHeader />,
        cell: ({ row, getValue }) => (
          <AlertIconTooltip
            hasAlert={getValue()}
            resultsInAlert={row.original.conceptDriftResults}
            disabled={!row.original.conceptDriftEnabled}
            getResultName={(nameElements) => [getMetricName(nameElements.metric ?? "", nameElements.analysisType)]}
            linkTo={getModelPlotPath(row.original.modelId, ModelResultsViewsOptions.ConceptShift)}
          />
        ),
      }),
    ],
  }),

  columnHelper.group({
    id: "dataDriftAlerts",
    header: () => <TopLevelHeader>Covariate shift</TopLevelHeader>,
    columns: [
      columnHelper.accessor("dataDriftAlerts", {
        header: () => <MidLevelHeader />,
        cell: ({ row, getValue }) => (
          <AlertIconTooltip
            hasAlert={getValue()}
            resultsInAlert={row.original.dataDriftResults}
            getResultName={(nameElements) => getNameWithColumn(nameElements, "Multivariate covariate shift")}
            linkTo={getModelPlotPath(row.original.modelId, ModelResultsViewsOptions.CovariateShift)}
          />
        ),
      }),
    ],
  }),

  columnHelper.group({
    id: "dataQualityAlerts",
    header: () => <TopLevelHeader>Data quality</TopLevelHeader>,
    meta: {
      className: "border-r border-disabledGray",
    },
    columns: [
      columnHelper.accessor("dataQualityAlerts", {
        header: () => <MidLevelHeader />,
        meta: {
          className: "border-r border-disabledGray",
        },
        cell: ({ row, getValue }) => (
          <AlertIconTooltip
            hasAlert={getValue()}
            resultsInAlert={row.original.dataQualityResults}
            getResultName={getNameWithColumn}
            linkTo={getModelPlotPath(row.original.modelId, ModelResultsViewsOptions.DataQuality)}
          />
        ),
      }),
    ],
  }),

  columnHelper.group({
    id: "monitoringStatus",
    header: () => <TopLevelHeader>Monitoring status</TopLevelHeader>,
    columns: [
      columnHelper.accessor("latestRun", {
        header: () => <MidLevelHeader />,
        cell: ({ row, getValue }) => (
          <div className="flex items-center justify-center">
            <RunStatusIcon run={getValue()} url={`/monitoring/model/${row.original.modelId}/runs`} />
          </div>
        ),
      }),
    ],
  }),
];
