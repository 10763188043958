import { ExternalLinkIcon, LineChartIcon, XIcon } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";

import { SimpleTooltip } from "@/DesignSystem/nanny/SimpleTooltip/SimpleTooltip";
import { getResultRef } from "@/adapters/monitoring";
import { FragmentType, ResultRefInput, gql, useFragment } from "@/apis/nannyml";
import { Card } from "@/components/Card";
import { Chip } from "@/components/Chip";
import { LabeledField } from "@/components/LabeledField";
import { Button } from "@/components/common/Button";
import { exceedsLowerThreshold, exceedsThreshold, exceedsUpperThreshold } from "@/domains/threshold";
import { getResultTitles } from "@/formatters/monitoring";
import { calculateResultThreshold } from "@/hooks/monitoring";
import { isNumber } from "@/lib/numbersUtils";
import { cn } from "@/lib/utils";

const metricCardFragment = gql(/* GraphQL */ `
  fragment MetricCard on TimeSeriesResult {
    __typename
    id
    modelId
    analysisType
    calculatorType
    metricName
    componentName
    columnName
    columnNames
    tags
    segment {
      id
      segmentColumnName
      segment
    }

    ...TimeSeriesResultThreshold
    lastDataPoint {
      value
    }
  }
`);

export const MetricCard = ({
  className,
  result: resultFragment,
  detailsLink,
  isChartOpen,
  onCardRemove,
  onChartOpenChange,
  flags,
}: {
  className?: string;
  result: FragmentType<typeof metricCardFragment>;
  detailsLink?: string;
  isChartOpen: boolean;
  onCardRemove: (resultRef: ResultRefInput, resultId: string) => void;
  onChartOpenChange: (open: boolean) => void;
  flags: string[];
}) => {
  const result = useFragment(metricCardFragment, resultFragment);
  const navigate = useNavigate();
  const threshold = calculateResultThreshold(result);

  const titles = getResultTitles(result);

  const onCardClick = () => {
    navigate(detailsLink ?? "#");
  };

  const segment = result.segment ? `${result.segment.segmentColumnName}: ${result.segment.segment}` : "All data";

  return (
    <Card className={cn("flex flex-col p-2 bg-primaryBg border-0", className)} onClick={onCardClick}>
      <div className="flex h-6">
        <span className="text-gray-400 truncate mr-auto" title={segment}>
          {segment}
        </span>
        {flags.map((flag) => (
          <Chip key={flag} label={flag} size="small" />
        ))}
        <div className="flex">
          <SimpleTooltip tooltipContent="Display chart">
            <Button
              cva={{ intent: "icon", size: "chip" }}
              onClick={(e) => (e.stopPropagation(), onChartOpenChange(!isChartOpen))}
              aria-pressed={isChartOpen}
              className="text-placeholder"
            >
              <LineChartIcon size={18} strokeWidth={2} />
            </Button>
          </SimpleTooltip>
          <SimpleTooltip tooltipContent="Open in new tab">
            <Link
              target="_blank"
              to={detailsLink ?? "#"}
              className="text-placeholder hover:text-highlightDeep"
              onClick={(e) => e.stopPropagation()}
            >
              <Button cva={{ intent: "icon", size: "chip" }} className="text-placeholder">
                <ExternalLinkIcon size={18} strokeWidth={2} />
              </Button>
            </Link>
          </SimpleTooltip>
          <SimpleTooltip tooltipContent="Remove from summary">
            <Button
              cva={{ intent: "icon", size: "chip" }}
              onClick={(e) => (e.stopPropagation(), onCardRemove(getResultRef(result), result.id))}
              className="text-placeholder"
            >
              <XIcon size={18} strokeWidth={2} />
            </Button>
          </SimpleTooltip>
        </div>
      </div>

      <div className="mt-auto flex flex-col pb-1 border-b border-disabledGray">
        <span className="whitespace-nowrap font-bold">{titles.title}</span>
        <span className="truncate text-sm" title={titles.subtitle}>
          {titles.subtitle}
        </span>
      </div>

      <div className="flex gap-4 justify-between">
        <LabeledField
          label="Value"
          className={cn(
            "text-2xl",
            exceedsThreshold(result.lastDataPoint?.value, threshold) ? "text-alert" : "text-confirmationPale"
          )}
        >
          {isNumber(result.lastDataPoint?.value) ? result.lastDataPoint.value.toPrecision(2) : "-"}
        </LabeledField>

        <LabeledField className="flex items-center text-sm" label="Thresholds">
          <div className="flex flex-col w-fit">
            <span className={cn(exceedsUpperThreshold(result.lastDataPoint?.value, threshold) && "text-alert")}>
              {isNumber(threshold.upper) ? threshold.upper.toPrecision(2) : "-"}
            </span>
            <span className={cn(exceedsLowerThreshold(result.lastDataPoint?.value, threshold) && "text-alert")}>
              {isNumber(threshold.lower) ? threshold.lower.toPrecision(2) : "-"}
            </span>
          </div>
        </LabeledField>
      </div>
    </Card>
  );
};
