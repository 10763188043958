import { Navigate, Outlet, RouteObject } from "react-router-dom";

import { MonitoringModelName } from "@/components/monitoring";
import { ApplicationSetup } from "@/pages/ApplicationSetup";
import { Header } from "@/pages/Header";
import { Help } from "@/pages/Help";
import { Settings } from "@/pages/Settings/Settings";
import {
  AddEvaluationModelWizard,
  EvaluationModelContainer,
  EvaluationModelHub,
  EvaluationModelNameFromRoute,
  EvaluationModelSummary,
  EvaluationModelResultsRoute,
  EvaluationModelResultsRouteName,
  EvaluationModelSettings,
} from "@/pages/evaluation";
import {
  AddExperimentWizard,
  ExperimentHub,
  ExperimentContainer,
  ExperimentNameFromRoute,
  ExperimentSummary,
  ExperimentResultsRoute,
  ExperimentResultsRouteName,
  ExperimentSettings,
} from "@/pages/experiment";
import { AddMonitoringModelWizard, ModelMonitoringHub } from "@/pages/monitoring";
import { ModelContainer } from "@/pages/monitoring/ModelContainer/ModelContainer";
import { ModelResultRoute, ModelResultRouteName } from "@/pages/monitoring/ModelResultView";
import { ModelRunOverview } from "@/pages/monitoring/ModelRunOverview/ModelRunOverview";
import { ModelSettings } from "@/pages/monitoring/ModelSettings/ModelSettings";
import { ModelSummary } from "@/pages/monitoring/Summary/Summary";

import App from "../App";
import { ErrorPage } from "./ErrorPage";

const NavigatePrefix = ({ prefix }: { prefix: string }) => {
  return <Navigate to={prefix + location.pathname} replace />;
};

export const routes: RouteObject[] = [
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/setup",
        element: <ApplicationSetup />,
      },
      {
        path: "/",
        element: (
          <Header>
            <Outlet />
          </Header>
        ),
        children: [
          {
            path: "/help",
            element: <Help />,
            handle: {
              breadcrumb: "Help",
            },
          },
          {
            path: "/",
            element: <Navigate to="/monitoring" replace />,
          },
          {
            path: "/overview",
            element: <Navigate to="/monitoring" replace />,
          },
          {
            path: "/model/*",
            element: <NavigatePrefix prefix="/monitoring" />,
          },
          {
            path: "/monitoring",
            element: <Outlet />,
            errorElement: <ErrorPage />,
            handle: {
              breadcrumb: "Hub",
              title: "Monitoring",
              root: true,
            },
            children: [
              {
                path: "",
                element: <ModelMonitoringHub />,
              },
              {
                path: "add",
                element: <AddMonitoringModelWizard />,
                handle: {
                  breadcrumb: "Add model",
                },
              },
              {
                path: "model/:modelId",
                element: <ModelContainer />,
                handle: {
                  breadcrumb: <MonitoringModelName />,
                },
                children: [
                  {
                    path: "",
                    element: <Navigate to="summary" replace />,
                  },
                  {
                    path: "summary",
                    element: <ModelSummary />,
                    handle: {
                      breadcrumb: "Summary",
                    },
                  },
                  {
                    path: "runs",
                    element: <ModelRunOverview />,
                    handle: {
                      breadcrumb: "Logs",
                    },
                  },
                  {
                    path: "settings",
                    element: <ModelSettings />,
                    handle: {
                      breadcrumb: "Settings",
                    },
                    children: ModelSettings.routes,
                  },
                  {
                    path: ":resultView",
                    element: <ModelResultRoute />,
                    handle: {
                      breadcrumb: <ModelResultRouteName />,
                    },
                  },
                ],
              },
            ],
          },
          {
            path: "/evaluation",
            element: <Outlet />,
            errorElement: <ErrorPage />,
            handle: {
              breadcrumb: "Hub",
              title: "Model evaluation",
              root: true,
            },
            children: [
              {
                path: "",
                element: <EvaluationModelHub />,
              },
              {
                path: "add",
                element: <AddEvaluationModelWizard />,
                handle: {
                  breadcrumb: "Add model",
                },
              },
              {
                path: ":modelId",
                element: (
                  <EvaluationModelContainer>
                    <Outlet />
                  </EvaluationModelContainer>
                ),
                handle: {
                  breadcrumb: <EvaluationModelNameFromRoute />,
                },
                children: [
                  {
                    path: "",
                    element: <EvaluationModelSummary />,
                    handle: {
                      breadcrumb: "Summary",
                    },
                  },
                  {
                    path: "settings",
                    element: <EvaluationModelSettings />,
                    handle: {
                      breadcrumb: "Settings",
                    },
                  },
                  {
                    path: ":resultView",
                    element: <EvaluationModelResultsRoute />,
                    handle: {
                      breadcrumb: <EvaluationModelResultsRouteName />,
                    },
                  },
                ],
              },
            ],
          },
          {
            path: "/experiment",
            element: <Outlet />,
            errorElement: <ErrorPage />,
            handle: {
              breadcrumb: "Hub",
              title: "Experiment",
              root: true,
            },
            children: [
              {
                path: "",
                element: <ExperimentHub />,
              },
              {
                path: "add",
                element: <AddExperimentWizard />,
                handle: {
                  breadcrumb: "Add experiment",
                },
              },
              {
                path: ":experimentId",
                element: (
                  <ExperimentContainer>
                    <Outlet />
                  </ExperimentContainer>
                ),
                handle: {
                  breadcrumb: <ExperimentNameFromRoute />,
                },
                children: [
                  {
                    path: "",
                    element: <ExperimentSummary />,
                    handle: {
                      breadcrumb: "Summary",
                    },
                  },
                  {
                    path: "settings",
                    element: <ExperimentSettings />,
                    handle: {
                      breadcrumb: "Settings",
                    },
                  },
                  {
                    path: ":resultView",
                    element: <ExperimentResultsRoute />,
                    handle: {
                      breadcrumb: <ExperimentResultsRouteName />,
                    },
                  },
                ],
              },
            ],
          },
          {
            path: "/settings",
            element: <Settings />,
            handle: {
              breadcrumb: "Settings",
            },
          },
        ],
      },
    ],
  },
];
