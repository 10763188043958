import { useApolloClient, useMutation, useSuspenseQuery } from "@apollo/client";

import { FragmentType, Tag, gql, useFragment } from "@/apis/nannyml";
import { getTags } from "@/apis/nannyml/queries/getTags";
import { ThresholdValue, calculateThreshold } from "@/domains/threshold";

const resultThresholdDetailsFragment = gql(/* GraphQL */ `
  fragment TimeSeriesResultThreshold on TimeSeriesResult {
    segment {
      id
    }
    mean
    stdev
    config {
      lowerValueLimit
      upperValueLimit
      threshold {
        __typename
        ... on ConstantThreshold {
          lower
          upper
        }
        ... on StandardDeviationThreshold {
          stdLowerMultiplier
          stdUpperMultiplier
        }
      }
      segmentThresholds {
        segment {
          id
        }
        threshold {
          __typename
          ... on ConstantThreshold {
            lower
            upper
          }
          ... on StandardDeviationThreshold {
            stdLowerMultiplier
            stdUpperMultiplier
          }
        }
      }
    }
  }
`);

const startMonitoringModelRunMutation = gql(/* GraphQL */ `
  mutation StartMonitoringModelRun($modelId: Int!) {
    start_monitoring_model_run(modelId: $modelId) {
      id
    }
  }
`);

export const useStartMonitoringModelRun = (modelId: number) => {
  const client = useApolloClient();
  return useMutation(startMonitoringModelRunMutation, {
    variables: { modelId },
    onCompleted: () => {
      // Invalidate the cache for the evaluation model's latest run when the run starts
      client.cache.evict({ id: `Model:${modelId}`, fieldName: "latestRun" });
    },
  });
};

export const calculateResultThreshold = (
  fragment: FragmentType<typeof resultThresholdDetailsFragment>
): ThresholdValue => {
  const result = useFragment(resultThresholdDetailsFragment, fragment);
  const thresholdConfig =
    (result.segment && result.config.segmentThresholds.find((s) => s.segment.id === result.segment!.id)?.threshold) ??
    result.config.threshold;
  return calculateThreshold(result, {
    lowerValueLimit: result.config.lowerValueLimit,
    upperValueLimit: result.config.upperValueLimit,
    threshold: thresholdConfig,
  });
};

export const useTags = (): string[] => {
  const { data } = useSuspenseQuery(getTags);

  return data.tags.map((tag: Tag) => tag.name);
};

export const useInvalidateResultCache = () => {
  const client = useApolloClient();

  return (modelId: number) => {
    client.cache.evict({ id: `monitoring_model:${modelId}`, fieldName: "results" });
  };
};
