import { useQuery } from "@apollo/client";
import { useEffect, useRef, useState } from "react";
import { ImperativePanelHandle } from "react-resizable-panels";
import { Outlet, useMatches } from "react-router-dom";

import { MappingProblemTypeRead } from "@/adapters/model/modelMapping";
import { getModelSchema } from "@/apis/nannyml/queries/getModelSchema";
import { useParamsModelId } from "@/routes/useParamsModelId";

import { ModelNavBar } from "./ModelNavBar";

export const ModelContainer = () => {
  const modelId = useParamsModelId();
  const matched = useMatches();

  const navBarRef = useRef<ImperativePanelHandle | null>(null);

  const [sideBarIsCollapsed, setSideBarIsCollapsed] = useState(() => {
    return localStorage.getItem("sideBarIsCollapsed") === "true";
  });

  useEffect(() => {
    localStorage.setItem("sideBarIsCollapsed", sideBarIsCollapsed.toString());
  }, [sideBarIsCollapsed]);

  const { data } = useQuery(getModelSchema, {
    variables: { modelId: modelId! },
    skip: !modelId && modelId !== 0,
  });

  const pathName = matched.at(-1)?.pathname ?? "";

  const model = {
    name: data?.monitoring_model?.name,
    problemType: MappingProblemTypeRead[data?.monitoring_model?.problemType!],
  };

  const onCollapseNavBar = () => {
    navBarRef.current?.collapse();
    setSideBarIsCollapsed(true);
  };

  const onExpandNavBar = () => {
    setSideBarIsCollapsed(false);
  };

  return (
    <div className={"flex flex-grow bg-dark h-[1px]"}>
      <ModelNavBar
        isOverlay={false}
        modelId={modelId!}
        modelName={model.name!}
        problemType={model.problemType!}
        pathName={pathName}
        isCollapsed={sideBarIsCollapsed}
        onCollapseNavBar={onCollapseNavBar}
        onExpandNavBar={onExpandNavBar}
      />

      {/*{sideBarIsCollapsed ? null : (*/}
      {/*  <div*/}
      {/*    className={*/}
      {/*      "fixed z-40 w-[250px] h-full bg-cardBg rounded-r-xl pr-4 border-2 border-l-0 border-placeholder"*/}
      {/*    }*/}
      {/*    onMouseLeave={() => {*/}
      {/*      setSideBarIsCollapsed(true);*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <ModelNavBar*/}
      {/*      modelId={modelId!}*/}
      {/*      modelName={model.name!}*/}
      {/*      problemType={model.problemType!}*/}
      {/*      pathName={pathName}*/}
      {/*      isCollapsed={false}*/}
      {/*      onCollapseNavBar={onCollapseNavBar}*/}
      {/*      onExpandNavBar={onExpandNavBar}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*)}*/}

      <div className={"flex-grow w-1 overflow-y-auto"}>
        <Outlet />
      </div>
    </div>
  );
};
