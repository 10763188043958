import { RunEventType } from "@/apis/nannyml";

export const runEventTypeLabels: Record<RunEventType, string> = {
  [RunEventType.Cancelled]: "Cancelled",
  [RunEventType.Completed]: "Completed",
  [RunEventType.Error]: "Error",
  [RunEventType.Progress]: "Progress",
  [RunEventType.Scheduled]: "Scheduled",
  [RunEventType.Started]: "Started",
  [RunEventType.Timeout]: "Timeout",
  [RunEventType.Warning]: "Warning",
  [RunEventType.InfrastructureInfo]: "Infrastructure info",
  [RunEventType.InfrastructureWarning]: "Infrastructure warning",
  [RunEventType.InfrastructureError]: "Infrastructure error",
};
