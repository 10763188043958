import { useApolloClient } from "@apollo/client";
import { CheckCircle2Icon, Copy, Loader2Icon, Minus, Plus, XCircleIcon } from "lucide-react";
import React, { useLayoutEffect, useState } from "react";

import { WebhookNotificationHeaderInput, WebhookNotificationSettingsInput } from "@/apis/nannyml";
import { sendTestWebhookCall } from "@/apis/nannyml/queries/sendTestWebhookCall";
import { ButtonGroup } from "@/components/ButtonGroup";
import { Card } from "@/components/Card";
import { RadioGroup, RadioGroupItem } from "@/components/RadioGroup";
import { Button } from "@/components/common/Button";
import { SimpleInput } from "@/components/common/FormElements/SimpleInput/SimpleInput";
import { toast } from "@/components/common/Toast/useToast";
import { InformationModalChip } from "@/components/dashboard/InformationModal/InformationModalChip";
import { WebhookMode } from "@/constants/enums";
import { webhookModeLabels } from "@/formatters/applicationConfiguration";
import { cn } from "@/lib/utils";

const getWebhookMode = (settings?: WebhookNotificationSettingsInput | null): WebhookMode | undefined => {
  if (!settings) {
    return undefined;
  } else if (!settings.enabled) {
    return WebhookMode.Disabled;
  } else {
    return WebhookMode.Custom;
  }
};

type WebhookSetupProps = {
  className?: string;
  webhookSettings: WebhookNotificationSettingsInput | null;
  onChange: (webhook: WebhookNotificationSettingsInput | null) => void;
};

export const WebhookSetup = ({ webhookSettings, className, onChange }: WebhookSetupProps) => {
  const [webhookMode, setWebhookMode] = useState(getWebhookMode(webhookSettings));

  if (!webhookMode) {
    return (
      <div className={cn("flex flex-col justify-center items-center", className)}>
        <h3 className="text-xl font-bold mb-10">Select webhook configuration</h3>
        <RadioGroup
          className="w-full flex gap-6"
          value={webhookMode}
          onValueChange={(value) => setWebhookMode(value as WebhookMode)}
        >
          {Object.values(WebhookMode).map((mode) => (
            <RadioGroupItem value={mode} key={mode} asChild>
              <Card className="basis-0 grow flex flex-col">
                <span className="font-bold mb-2">{webhookModeLabels[mode].label}</span>
                <span className="text-sm mb-5 text-gray-400">{webhookModeLabels[mode].description}</span>
                <span className="text-sm">{webhookModeLabels[mode].recommendation}</span>
              </Card>
            </RadioGroupItem>
          ))}
        </RadioGroup>
      </div>
    );
  }

  const WebhookDetailComponent = webhookDetailComponents[webhookMode];

  // If mode is selected, show small selector on top & configuration options for that mode below it.
  return (
    <div className={cn("flex flex-col items-center", className)}>
      <RadioGroup
        className="w-full mb-5"
        value={webhookMode}
        onValueChange={(value) => (setWebhookMode(value as WebhookMode), onChange(null))}
      >
        <ButtonGroup className="w-full">
          {Object.values(WebhookMode).map((mode) => (
            <RadioGroupItem className="basis-0 grow flex flex-col text-left" key={mode} value={mode}>
              <span>{webhookModeLabels[mode].label}</span>
              <span className="text-sm text-gray-400">{webhookModeLabels[mode].description}</span>
            </RadioGroupItem>
          ))}
        </ButtonGroup>
      </RadioGroup>
      {webhookMode && (
        <div className="w-full">
          <WebhookDetailComponent webhookSettings={webhookSettings} onChange={onChange} />
        </div>
      )}
    </div>
  );
};

const DisabledWebhookDetails = ({ className, onChange }: WebhookSetupProps) => {
  const { longDescription, recommendation } = webhookModeLabels[WebhookMode.Disabled];

  useLayoutEffect(() => {
    onChange({ enabled: false, address: "", additionalHeaders: [] });
  }, []);

  return (
    <div className={cn("grow flex flex-col gap-5", className)}>
      <p>{longDescription}</p>
      <p>{recommendation}</p>
    </div>
  );
};

const CustomWebhookDetails = ({ className, webhookSettings, onChange }: WebhookSetupProps) => {
  const { longDescription, recommendation } = webhookModeLabels[WebhookMode.Custom];
  const [address, setAddress] = useState(webhookSettings?.address ?? "");
  const [additionalHeaders, setAdditionalHeaders] = useState(webhookSettings?.additionalHeaders ?? []);

  return (
    <div className={cn("grow flex flex-col gap-5", className)}>
      <p>{longDescription}</p>
      <p>{recommendation}</p>
      <form className="flex flex-col w-full lg:w-3/4 xl:w-2/3" onSubmit={(e) => e.preventDefault()}>
        <div className="flex items-center mt-5 mb-1">
          <h3 className="text-lg font-bold ">Webhook configuration</h3>
          <InformationModalChip infoName="Webhook configuration" className={"mx-0"} />
        </div>
        <p className="text-sm text-gray-400 mb-3">
          These settings will be used to perform a POST request to your webhook address.
        </p>
        <div className="flex flex-col gap-3 mb-3">
          <SimpleInput
            containerClassName="grow"
            inputClassName="dark:border-gray-500"
            label="Address"
            placeholder="Webhook address"
            value={address ?? ""}
            onChange={setAddress}
            fieldName="host"
          />

          <h4 className="">Additional headers</h4>
          <WebhookHeadersSetup additionalHeaders={additionalHeaders} setAdditionalHeaders={setAdditionalHeaders} />
        </div>
        <hr className="border-gray-400 my-5" />
        <div className="text-sm text-gray-400 mb-3">
          <div className="mb-3">
            Send a test <em>POST</em> request with a dummy payload to the configured webhook endpoint before continuing.
          </div>
          <div className="mb-3">
            If the configuration is valid, you can continue to the next step. To debug in case of problems, use the
            following command or an equivalent for your system:
          </div>
          <div className="flex flex-row mb-3 bg-dark overflow-scroll">
            <div>
              <pre>{buildDebugCommand(address, additionalHeaders)}</pre>
            </div>
            <div className="p-2">
              <button
                onClick={() => {
                  navigator.clipboard
                    .writeText(buildDebugCommand(address, additionalHeaders))
                    .then(() =>
                      toast({
                        title: "Copied to clipboard",
                        variant: "info",
                      })
                    )
                    .catch((exc) =>
                      toast({
                        title: "Could not copy...",
                        variant: "error",
                      })
                    );
                }}
              >
                <Copy size={20} />
              </button>
            </div>
          </div>
        </div>
        <TestCustomWebhook
          onConnectionTest={(webhookSettings, success) => onChange(success ? webhookSettings : null)}
          webhookSettings={{ enabled: true, address, additionalHeaders }}
        />
      </form>
    </div>
  );
};

const webhookDetailComponents: Record<WebhookMode, React.FunctionComponent<WebhookSetupProps>> = {
  [WebhookMode.Disabled]: DisabledWebhookDetails,
  [WebhookMode.Custom]: CustomWebhookDetails,
};

export type Header = {
  key: string;
  value: string;
};

const WebhookHeadersSetup = ({
  additionalHeaders,
  setAdditionalHeaders,
}: {
  additionalHeaders: Array<WebhookNotificationHeaderInput>;
  setAdditionalHeaders: React.Dispatch<React.SetStateAction<WebhookNotificationHeaderInput[]>>;
}) => {
  const [newHeader, setNewHeader] = useState<Header>({ key: "", value: "" });
  return (
    <div>
      <div className="flex flex-row gap-3 mb-3 items-end">
        <SimpleInput
          containerClassName="grow"
          inputClassName="dark:border-gray-500"
          label="Key"
          placeholder="Header key"
          value={newHeader?.key ?? ""}
          onChange={(k) => setNewHeader({ ...newHeader, key: k })}
          fieldName="key"
        />
        <SimpleInput
          containerClassName="grow"
          inputClassName="dark:border-gray-500"
          label="Value"
          placeholder="Header value"
          value={newHeader.value}
          onChange={(v) => setNewHeader({ ...newHeader, value: v })}
          fieldName="value"
        />
        <Button
          className="bg-dark border-dark border p-1 rounded hover:bg-primaryBg hover:border-cyan-400 h-fit mb-1"
          onClick={(e) => {
            setAdditionalHeaders((h) => [...h, { key: newHeader.key, value: newHeader.value }]);
            setNewHeader({ key: "", value: "" });
          }}
        >
          <Plus />
        </Button>
      </div>
      <div className="flex flex-col">
        {additionalHeaders.map(({ key, value }: Header, index) => (
          <div className="flex flex-row gap-3 mb-3 items-end" key={key}>
            <SimpleInput
              containerClassName="grow"
              inputClassName="dark:border-gray-500"
              disabled={true}
              value={key}
              fieldName={`key-${index}`}
            />
            <SimpleInput
              containerClassName="grow"
              inputClassName="dark:border-gray-500"
              disabled={true}
              value={value}
              fieldName={`value-${index}`}
            />
            <Button
              className="bg-dark border-dark border p-1 rounded hover:bg-primaryBg hover:border-cyan-400 h-fit mb-1"
              onClick={() => {
                setAdditionalHeaders(additionalHeaders.filter((_, i) => i !== index));
              }}
            >
              <Minus />
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};

const TestCustomWebhook = ({
  className,
  onConnectionTest,
  webhookSettings,
}: {
  className?: string;
  onConnectionTest: (webhookSettings: WebhookNotificationSettingsInput, result: boolean) => void;
  webhookSettings: WebhookNotificationSettingsInput;
}) => {
  const client = useApolloClient();
  const [testResult, setTestResult] = useState<boolean | string | null>(null);
  const [testIsActive, setTestIsActive] = useState<boolean>(false);

  const callWebhook = () => {
    setTestIsActive(true);
    setTestResult(null);

    client
      .query({
        query: sendTestWebhookCall,
        fetchPolicy: "no-cache",
        variables: {
          webhookSettings,
        },
      })
      .then(() => {
        setTestResult(true);
        onConnectionTest(webhookSettings, true);
      })
      .catch((error) => {
        setTestResult(error.message);
        onConnectionTest(webhookSettings, false);
      })
      .finally(() => setTestIsActive(false));
  };

  return (
    <div>
      <div className={cn("flex gap-3 items-end", className)}>
        <Button
          cva={{ size: "small2", intent: "action", border: "thin" }}
          onClick={callWebhook}
          type="submit"
          disabled={testIsActive}
        >
          {testIsActive ? (
            <>
              <span>Sending POST request...</span>
              <Loader2Icon className="animate-spin" />
            </>
          ) : (
            "Validate webhook"
          )}
        </Button>
      </div>
      {testResult === true ? (
        <div className="flex items-center gap-2 text-green-600 mt-2">
          <CheckCircle2Icon className="shrink-0" size={20} />
          <span>Webhook endpoint responded successfully</span>
        </div>
      ) : (
        testResult !== null && (
          <div className="flex items-center gap-2 text-red-600 mt-2">
            <XCircleIcon className="shrink-0" size={20} />
            <span>
              Failed to send webhook call due to HTTP error: <i>{testResult}</i>
            </span>
          </div>
        )
      )}
    </div>
  );
};

const buildDebugCommand = (address: string, headers: Array<WebhookNotificationHeaderInput>) =>
  `
    curl -v -X POST -H 'Content-type: application/json' \\
    ${headers.map((h) => `-H '${h.key}: ${h.value}'`).join(" ")} \\
    ${address} \\
    --data '{"message": "Hello from NannyML" }'
  `;
