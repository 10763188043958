import { AlertStatusFilter, PerformancePlotMode, SortOrder } from "@/constants/enums";

export const alertStatusFilterLabels: Record<AlertStatusFilter, string> = {
  [AlertStatusFilter.NoAlerts]: "No alerts",
  [AlertStatusFilter.AlertInAnyChunk]: "Alert in any chunk",
  [AlertStatusFilter.AlertInLastChunk]: "Alert in last chunk",
  [AlertStatusFilter.Everything]: "Everything",
  [AlertStatusFilter.AlertForMainPerformanceMetric]: "Alert for main performance metric",
};

export const performancePlotModeLabels: Record<PerformancePlotMode, string> = {
  [PerformancePlotMode.EstimatedPerformance]: "Estimated performance",
  [PerformancePlotMode.RealizedPerformance]: "Realized performance",
  [PerformancePlotMode.RealizedAndEstimatedPerformance]: "Both realized and estimated",
  [PerformancePlotMode.CompareRealizedToEstimatedPerformance]: "Compare realized to estimated",
};

export const sortOrderLabels: Record<SortOrder, string> = {
  [SortOrder.NrOfAlers]: "Nr of alerts",
  [SortOrder.RecencyOfAlerts]: "Recency of alerts",
  [SortOrder.Metric]: "Metric name",
  [SortOrder.Method]: "Method name",
  [SortOrder.Column]: "Column name",
};
