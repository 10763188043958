import { useMutation } from "@apollo/client";
import { AlertTriangleIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";

import { Input } from "@/DesignSystem/shadcn/Input";
import { DayOfWeek, EditScheduleInput, FragmentType, ScheduleFrequency, gql, useFragment } from "@/apis/nannyml";
import { confirm } from "@/components/Dialog";
import { LabeledField } from "@/components/LabeledField";
import { Select, SelectItem } from "@/components/Select";
import { Button } from "@/components/common/Button";
import { resolveWithToast } from "@/components/common/Toast/useToast";
import { ChunkingConfig } from "@/components/monitoring/RuntimeConfig";
import { problemTypeLabels } from "@/formatters/models";
import { dayOfWeekLabels, scheduleFrequencyLabels } from "@/formatters/schedule";
import { cn } from "@/lib/utils";

import { EditModelControls, Subtitle, Title, useModelEditor } from "./EditModel";

const modelDetailsFragment = gql(/* GraphQL */ `
  fragment MonitoringModelDetails on Model {
    id
    name
    problemType
    createdAt
  }
`);

const monitoringModelScheduleFragment = gql(/* GraphQL */ `
  fragment MonitoringModelSchedule on Model {
    schedules {
      frequency
      multiplier
      offsetDays
      time
      dayOfWeek
    }
  }
`);

const deleteModelMutation = gql(/* GraphQL */ `
  mutation DeleteModel($modelId: Int!) {
    delete_monitoring_model(modelId: $modelId) {
      id
    }
  }
`);

export const GeneralSettings = () => {
  return (
    <div className="p-4 flex flex-col w-fit h-full gap-8">
      <ModelDetails />
      <ChunkingSettings />
      <ScheduleSettings />
      <EditModelControls className="sticky bottom-0 py-4 -my-4 bg-dark" />
      <DangerZone />
    </div>
  );
};

const ModelDetails = () => {
  const editor = useModelEditor();
  const model = useFragment(modelDetailsFragment, editor.model);

  return (
    <div className="grid grid-cols-2 gap-4">
      <Title className="col-span-full">Model details</Title>
      <LabeledField className="col-span-full" label="Model name">
        <Input
          value={model.name}
          onChange={(e) => editor.onModelChange({ name: e.target.value })}
          placeholder="Model name"
          required
        />
      </LabeledField>
      <LabeledField label="Problem type" className="text-sm">
        {problemTypeLabels[model.problemType]}
      </LabeledField>
      <LabeledField label="Created at" className="text-sm">
        {new Date(model.createdAt).toLocaleString()}
      </LabeledField>
    </div>
  );
};

const ChunkingSettings = () => {
  const editor = useModelEditor();

  return (
    <div className="flex flex-col gap-4">
      <Title>Chunking configuration</Title>
      <Subtitle>
        Configure how data is aggregated into chunks for analysis. Either by time or by a fixed number of data points.
      </Subtitle>
      <ChunkingConfig value={editor.model.runtimeConfig} onValueChange={editor.onRuntimeConfigChange} />
    </div>
  );
};

const scheduleFragmentToScheduleInput = (
  scheduleFragment: FragmentType<typeof monitoringModelScheduleFragment>
): EditScheduleInput => {
  const {
    schedules: [schedule],
  } = useFragment(monitoringModelScheduleFragment, scheduleFragment);
  return {
    frequency: schedule.frequency,
    multiplier: schedule.multiplier,
    offsetDays: schedule.offsetDays,
    time: schedule.time,
    dayOfWeek: schedule.dayOfWeek,
  };
};

const ScheduleSettings = ({ className }: { className?: string }) => {
  const editor = useModelEditor();
  const schedule = scheduleFragmentToScheduleInput(editor.model);

  const onScheduleChange = (change: Partial<EditScheduleInput>) => {
    editor.onModelChange({ schedules: [{ ...schedule, ...change }] });
  };

  return (
    <div className={cn("flex flex-col gap-4", className)}>
      <Title>Analysis schedule</Title>
      <Subtitle>Configure a schedule to perform analysis of new data since the latest NannyML run.</Subtitle>
      <div className="flex items-center gap-2">
        <LabeledField label="Every">
          <Select
            className="h-10 w-32"
            value={schedule.frequency}
            onValueChange={(frequency) =>
              onScheduleChange({
                frequency: frequency as ScheduleFrequency,
                offsetDays: frequency === ScheduleFrequency.Weekly ? undefined : 1,
                dayOfWeek: frequency === ScheduleFrequency.Weekly ? DayOfWeek.Monday : undefined,
                multiplier: 1,
              })
            }
            required
          >
            {Object.entries(scheduleFrequencyLabels).map(([frequency, label]) => (
              <SelectItem key={frequency} value={frequency}>
                {label}
              </SelectItem>
            ))}
          </Select>
        </LabeledField>
        {schedule.frequency === ScheduleFrequency.Weekly && (
          <LabeledField label="On">
            <Select
              className="h-10 w-32"
              value={schedule.dayOfWeek ?? DayOfWeek.Monday}
              onValueChange={(dayOfWeek) => onScheduleChange({ dayOfWeek: dayOfWeek as DayOfWeek })}
              required
            >
              {Object.entries(dayOfWeekLabels).map(([dayOfWeek, label]) => (
                <SelectItem key={dayOfWeek} value={dayOfWeek}>
                  {label}
                </SelectItem>
              ))}
            </Select>
          </LabeledField>
        )}
        {schedule.frequency === ScheduleFrequency.Monthly && (
          <LabeledField label="On (day of month)">
            <Input
              className="w-32 hide-arrows"
              type="number"
              min="1"
              max="31"
              value={schedule.offsetDays ?? ""}
              onChange={(e) => onScheduleChange({ offsetDays: e.target.value ? parseInt(e.target.value) : null })}
              required
            />
          </LabeledField>
        )}
        {schedule.frequency === ScheduleFrequency.Quarterly && (
          <LabeledField label="On (day of quarter)">
            <Input
              className="w-32 hide-arrows"
              type="number"
              min="1"
              max="92"
              value={schedule.offsetDays ?? ""}
              onChange={(e) => onScheduleChange({ offsetDays: e.target.value ? parseInt(e.target.value) : null })}
              required
            />
          </LabeledField>
        )}
        {schedule.frequency === ScheduleFrequency.Yearly && (
          <LabeledField label="On (day of year)">
            <Input
              className="w-32 hide-arrows"
              type="number"
              min="1"
              max="366"
              value={schedule.offsetDays ?? ""}
              onChange={(e) => onScheduleChange({ offsetDays: e.target.value ? parseInt(e.target.value) : null })}
              required
            />
          </LabeledField>
        )}
        {schedule.frequency !== ScheduleFrequency.Hourly && (
          <LabeledField label={`At (${Intl.DateTimeFormat().resolvedOptions().timeZone})`}>
            <Input
              type="time"
              value={isoTimeToLocalTime(schedule.time)}
              onChange={(e) => onScheduleChange({ time: localTimeToIsoTime(e.target.value) })}
              required
            />
          </LabeledField>
        )}
      </div>
    </div>
  );
};

const isoTimeToLocalTime = (isoTime: string): string => {
  const d = new Date(`2000-01-01T${isoTime}`);
  // Use en-GB locale to get 24-hour time format
  return d.toLocaleTimeString("en-GB");
};

const localTimeToIsoTime = (localTime: string): string => {
  const d = new Date(`2000-01-01T${localTime}`);
  // Extract time part from ISO string and convert 'Z' to '+00:00' (python doesn't support 'Z' timezone format)
  return d.toISOString().split("T")[1].replace(/Z$/, "+00:00");
};

const DangerZone = ({ className }: { className?: string }) => {
  const editor = useModelEditor();
  const model = useFragment(modelDetailsFragment, editor.model);
  const navigate = useNavigate();
  const [deleteModel] = useMutation(deleteModelMutation, {
    update(cache) {
      // Remove deleted model from cache, then garbage collect any orphaned references
      cache.evict({ id: `Model:${model.id}` });
      cache.gc();
    },
  });

  const deleteModelWithToast = (modelId: number) => {
    const promise = deleteModel({
      variables: { modelId },
    });

    resolveWithToast(promise, {
      title: "Deleting model...",
      successDescription: "✅ Model deleted",
      errorDescription: "❌ Model couldn't be deleted",
      retry: () => deleteModelWithToast(modelId),
    });

    return promise;
  };

  const onModelDelete = () => {
    confirm({
      title: "Delete model",
      message: <DeleteModelDialog modelName={model.name} />,
      confirmLabel: "Delete",
      cancelIntent: "primary",
      confirmIntent: "secondary",
    }).then((confirmed) => {
      if (confirmed) {
        deleteModelWithToast(model.id).then(() => navigate("/monitoring"));
      }
    });
  };

  return (
    <div className={cn("flex flex-col gap-4", className)}>
      <Title className="border-red-500 text-red-500">Danger zone</Title>
      <Subtitle>
        This section allows you to make changes to the model that could break things. Make sure you know what you're
        doing before applying changes.
      </Subtitle>
      <div className="flex justify-between mt-4 items-center gap-4">
        <div className="flex flex-col">
          <p className="font-semibold">Delete model</p>
          <p className="text-sm text-gray-400">
            Deleting a model will permanently delete all its results, data and settings. This cannot be undone.
          </p>
        </div>
        <Button
          label={"Delete model"}
          cva={{ intent: "reject" }}
          className="p-2 justify-center whitespace-nowrap h-fit"
          onClick={onModelDelete}
        />
      </div>
    </div>
  );
};

const DeleteModelDialog = ({ modelName }: { modelName: string }) => (
  <div className="flex mb-3 items-center">
    <AlertTriangleIcon className="inline-block mr-5 text-warningPale" size={64} />
    <div className="flex flex-col gap-3">
      <p>
        Deleting a model will <b>permanently delete</b> all its results, data and settings. This cannot be undone.
      </p>
      <p>Are you sure you want to delete model '{modelName}'?</p>
    </div>
  </div>
);
