import { useSuspenseQuery } from "@apollo/client";
import _ from "lodash";
import React from "react";
import { useParams } from "react-router-dom";

import { gql } from "@/apis/nannyml";
import { SelectCheckboxList } from "@/components/dashboard/SelectCheckbox/SelectCheckboxList";

import { useResultFilter, useResultFilterConfig } from "./ResultFilters.hooks";

const getModelSegmentsQuery = gql(/* GraphQL */ `
  query GetModelSegments($modelId: Int!) {
    monitoring_model(id: $modelId) {
      segments {
        id
        segmentColumnName
        segment
      }
    }
  }
`);

export const FilterSegments = () => {
  const { modelId } = useParams();
  const { monitoring_model: model } = useSuspenseQuery(getModelSegmentsQuery, {
    variables: { modelId: parseInt(modelId!) },
  }).data;

  const [{ segments }, setFilterConfig] = useResultFilterConfig();

  if (!model) {
    throw new Error("Model not found");
  }

  // Apply selected segment filter to active results
  useResultFilter((resultGroups, { segments }) => {
    if (!segments || segments.length === 0) {
      return resultGroups;
    }

    return resultGroups.filter((results) => results.some((result) => segments.includes(result.segment?.id ?? null)));
  });

  const segmentGroups = React.useMemo(
    () =>
      [{ title: "", labels: { "": "All data" } as { [k: string]: string } }].concat(
        _.map(_.groupBy(model.segments, "segmentColumnName"), (segments, segmentColumnName) => ({
          title: segmentColumnName,
          labels: Object.fromEntries(segments.map(({ id, segment }) => [id, segment])),
        }))
      ),
    [model.segments]
  );
  const setSelectedSegments = (updateSegments: (currentSegments: string[]) => string[]) => {
    setFilterConfig({ segments: segmentIdsFromStrings(updateSegments(segmentIdsToStrings(segments))) });
  };

  return (
    <SelectCheckboxList
      groupClassName="max-h-80 overflow-y-auto"
      values={model.segments.map(({ id }) => id.toString()).concat("")}
      names={segmentGroups}
      selectedValues={segmentIdsToStrings(segments)}
      setSelectedValues={setSelectedSegments}
      showSearch={true}
      showSelectOnlyCurrent={true}
      searchClassName="border-0"
      emptyPlaceholder="No segments specified."
    />
  );
};

const segmentIdsToStrings = (segments?: (number | null)[]) =>
  segments?.map((segment) => segment?.toString() ?? "") ?? [];
const segmentIdsFromStrings = (segments: string[]) => segments.map((segment) => (segment ? parseInt(segment) : null));
