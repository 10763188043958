import { ResultOf } from "@graphql-typed-document-node/core";
import _ from "lodash";
import { FilterIcon } from "lucide-react";
import { ReactNode, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import { Text } from "@/DesignSystem/basic/Text/Text";
import { InputWithClearButton } from "@/DesignSystem/shadcn/Input/Input";
import { getResultRef } from "@/adapters/monitoring";
import {
  AnalysisType,
  CalculatorType,
  ConceptShiftMetric,
  DataQualityMetric,
  FragmentType,
  MultivariateDriftMethod,
  PerformanceMetric,
  ResultRefInput,
  SummaryStatsMetric,
  UnivariateDriftMethod,
  gql,
  useFragment,
} from "@/apis/nannyml";
import { Select, SelectItem } from "@/components/Select";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/Table";
import { Checkbox } from "@/components/common/Checkbox/Checkbox";
import { ResultView } from "@/domains/monitoring";
import { SUMMARY_TAG_NAME } from "@/domains/tags/tagsOptions";
import {
  conceptShiftMetricLabels,
  dataQualityMetricLabels,
  getResultLabels,
  multivariateDriftMethodLabels,
  performanceMetricLabels,
  resultViewLabels,
  summaryStatsMetricLabels,
  univariateDriftMethodLabels,
} from "@/formatters/monitoring";
import { useSearchList } from "@/hooks/useSearchList";
import { selectWhere } from "@/lib/typesUtils";

const summaryResultsFragment = gql(/* GraphQL */ `
  fragment SummaryResult on Result {
    __typename
    id
    modelId
    analysisType
    calculatorType
    tags

    ... on TimeSeriesResult {
      metricName
      componentName
      columnName
      columnNames
      segment {
        id
      }
    }
  }
`);

type TimeSeriesResultSummary = Extract<ResultOf<typeof summaryResultsFragment>, { __typename: "TimeSeriesResult" }>;

const useAvailableResults = (resultsFragment: FragmentType<typeof summaryResultsFragment>[]) => {
  return useFragment(summaryResultsFragment, resultsFragment).filter(selectWhere("__typename", "TimeSeriesResult"));
};

const Heading = ({ view, modelId }: { view: ResultView; modelId: number }) => (
  <>
    <Text size="large">{resultViewLabels[view]}</Text>
    <Text>Select {resultViewLabels[view].toLowerCase()} metrics you want to see in the summary view.</Text>
    <Text className="text-sm text-gray-400 -mt-4">
      The metrics selected here apply to the entire dataset. You can add segment-specific metrics by adding a summary
      tag on{" "}
      <Link to={`/monitoring/model/${modelId}/${view}`} className="underline">
        the {resultViewLabels[view].toLowerCase()} page
      </Link>
      .
    </Text>
  </>
);

const NoResultPlaceholder = ({ results, children }: { results: TimeSeriesResultSummary[]; children: ReactNode }) =>
  results.length > 0 ? (
    <>{children}</>
  ) : (
    <td className="text-center text-gray-400 p-8" colSpan={100}>
      Looks like there are no results yet. Make sure the desired analyses are enabled in the settings page. Run analysis
      to generate results.
    </td>
  );

export const EditSummaryPerformance = ({
  modelId,
  results: resultsFragment,
  onResultSelect,
}: {
  modelId: number;
  results: FragmentType<typeof summaryResultsFragment>[];
  onResultSelect: (resultRef: ResultRefInput, resultId: string, selected: boolean) => void;
}) => {
  const results = useAvailableResults(resultsFragment);

  // Group results by metric and component name
  const groupedResults = _.groupBy(
    results,
    (result) => `${result.metricName}-${result.componentName ?? result.metricName}`
  );

  return (
    <div className="flex flex-col gap-6 max-w-[600px]">
      <Heading view={ResultView.Performance} modelId={modelId} />
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Metric name</TableHead>
            <TableHead className="text-center">Show estimated</TableHead>
            <TableHead className="text-center">Show realized</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          <NoResultPlaceholder results={results}>
            {Object.entries(groupedResults).map(([key, results]) => (
              <EditSummaryPerformanceRow key={key} results={results} onResultSelect={onResultSelect} />
            ))}
          </NoResultPlaceholder>
        </TableBody>
      </Table>
    </div>
  );
};

const EditSummaryPerformanceRow = ({
  results,
  onResultSelect,
}: {
  results: TimeSeriesResultSummary[];
  onResultSelect: (resultRef: ResultRefInput, resultId: string, selected: boolean) => void;
}) => {
  const estimated = results.find((r) => r.analysisType === AnalysisType.EstimatedPerformance);
  const realized = results.find((r) => r.analysisType === AnalysisType.RealizedPerformance);

  return (
    <TableRow>
      <TableCell>{performanceMetricLabels[results[0].metricName.toUpperCase() as PerformanceMetric]}</TableCell>
      <TableCell className="text-center align-middle">
        <Checkbox
          onCheckedChange={(state) => onResultSelect(getResultRef(estimated!), estimated!.id, Boolean(state))}
          checked={estimated?.tags?.includes(SUMMARY_TAG_NAME) ?? false}
          disabled={estimated === undefined}
        />
      </TableCell>
      <TableCell className="text-center align-middle">
        <Checkbox
          onCheckedChange={(state) => onResultSelect(getResultRef(realized!), realized!.id, Boolean(state))}
          checked={realized?.tags?.includes(SUMMARY_TAG_NAME) ?? false}
          disabled={realized === undefined}
        />
      </TableCell>
    </TableRow>
  );
};

export const EditSummaryConceptDrift = ({
  modelId,
  results: resultsFragment,
  onResultSelect,
}: {
  modelId: number;
  results: FragmentType<typeof summaryResultsFragment>[];
  onResultSelect: (resultRef: ResultRefInput, resultId: string, selected: boolean) => void;
}) => {
  const results = useAvailableResults(resultsFragment);

  return (
    <div className="flex flex-col gap-6 max-w-[600px]">
      <Heading view={ResultView.ConceptDrift} modelId={modelId} />
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Metric name</TableHead>
            <TableHead className="text-center">Show in summary</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          <NoResultPlaceholder results={results}>
            {results.map((result) => (
              <EditSummaryConceptDriftRow
                key={result.id}
                result={result}
                onResultSelect={(enabled) => onResultSelect(getResultRef(result), result.id, enabled)}
              />
            ))}
          </NoResultPlaceholder>
        </TableBody>
      </Table>
    </div>
  );
};

const EditSummaryConceptDriftRow = ({
  result,
  onResultSelect,
}: {
  result: TimeSeriesResultSummary;
  onResultSelect: (enabled: boolean) => void;
}) => {
  return (
    <TableRow>
      <TableCell>{conceptShiftMetricLabels[result.metricName.toUpperCase() as ConceptShiftMetric]}</TableCell>
      <TableCell className="text-center align-middle">
        <Checkbox
          onCheckedChange={(state) => onResultSelect(Boolean(state))}
          checked={result.tags.includes(SUMMARY_TAG_NAME) ?? false}
        />
      </TableCell>
    </TableRow>
  );
};

export const EditSummaryCovariateShift = ({
  modelId,
  results: resultsFragment,
  onResultSelect,
}: {
  modelId: number;
  results: FragmentType<typeof summaryResultsFragment>[];
  onResultSelect: (resultRef: ResultRefInput, resultId: string, selected: boolean) => void;
}) => {
  const results = useAvailableResults(resultsFragment);
  const [analysisFilter, setAnalysisFilter] = useState<string>("");
  const [methodFilter, setMethodFilter] = useState<string>("");
  const { search, setSearch, results: columnResults } = useSearchList(results, (r) => r.columnName ?? "");
  const [summaryStatsMetrics, univariateMethods, multivariateMethods] = useMemo(() => {
    const uniqueMetrics = _.uniqBy(results, (result) => result.calculatorType + result.metricName);
    const metrics: [string[], string[], string[]] = [[], [], []];
    uniqueMetrics.forEach((result) => {
      if (result.analysisType === AnalysisType.SummaryStats) {
        metrics[0].push(result.metricName);
      } else if (result.calculatorType === CalculatorType.UnivariateDrift) {
        metrics[1].push(result.metricName);
      } else {
        metrics[2].push(result.metricName);
      }
    });
    return metrics;
  }, [results]);

  const filterResults = useMemo(() => {
    let results = methodFilter
      ? columnResults.filter((r) => r.metricName === methodFilter.toLowerCase())
      : columnResults;
    if (!analysisFilter) {
      return results;
    } else if (analysisFilter === "summaryStats") {
      return results.filter((r) => r.analysisType === AnalysisType.SummaryStats);
    } else if (analysisFilter === "univariateDrift") {
      return results.filter((r) => r.calculatorType === CalculatorType.UnivariateDrift);
    } else {
      return results.filter(
        (r) => r.analysisType === AnalysisType.FeatureDrift && r.calculatorType !== CalculatorType.UnivariateDrift
      );
    }
  }, [analysisFilter, methodFilter, columnResults]);

  return (
    <div className="flex flex-col gap-6 w-min">
      <Heading view={ResultView.CovariateShift} modelId={modelId} />
      <Table className="whitespace-nowrap">
        <TableHeader className="before:absolute before:inset-0 before:-top-6 before:backdrop-blur">
          <TableRow>
            <TableHead>Analysis type</TableHead>
            <TableHead>Method</TableHead>
            <TableHead>Column name</TableHead>
            <TableHead className="text-center">Show in summary</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          <NoResultPlaceholder results={results}>
            <TableRow>
              <TableCell className="relative">
                <FilterIcon
                  size={16}
                  strokeWidth={1.5}
                  className="absolute text-slate-400 top-1/2 left-5 transform -translate-y-1/2"
                />
                <Select
                  className="pl-7 min-w-[200px]"
                  placeholder="Filter analysis type"
                  value={analysisFilter}
                  onValueChange={setAnalysisFilter}
                  onClear={() => setAnalysisFilter("")}
                >
                  <SelectItem value="univariateDrift">Univariate drift</SelectItem>
                  <SelectItem value="multivariateDrift">Multivariate drift</SelectItem>
                  <SelectItem value="summaryStats">Summary statistics</SelectItem>
                </Select>
              </TableCell>
              <TableCell className="relative">
                <FilterIcon
                  size={16}
                  strokeWidth={1.5}
                  className="absolute text-slate-400 top-1/2 left-5 transform -translate-y-1/2"
                />
                <Select
                  className="pl-7 min-w-[235px]"
                  placeholder="Filter method"
                  value={methodFilter}
                  onValueChange={setMethodFilter}
                  onClear={() => setMethodFilter("")}
                >
                  {univariateMethods.map((method) => (
                    <SelectItem key={method} value={method}>
                      {univariateDriftMethodLabels[method.toUpperCase() as UnivariateDriftMethod]}
                    </SelectItem>
                  ))}
                  {multivariateMethods.map((method) => (
                    <SelectItem key={method} value={method}>
                      {multivariateDriftMethodLabels[method.toUpperCase() as MultivariateDriftMethod]}
                    </SelectItem>
                  ))}
                  {summaryStatsMetrics.map((metric) => (
                    <SelectItem key={metric} value={metric}>
                      {summaryStatsMetricLabels[metric.toUpperCase() as SummaryStatsMetric]}
                    </SelectItem>
                  ))}
                </Select>
              </TableCell>
              <TableCell className="relative">
                <FilterIcon
                  size={16}
                  strokeWidth={1.5}
                  className="absolute text-slate-400 top-1/2 left-5 transform -translate-y-1/2"
                />
                <InputWithClearButton
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Filter column name"
                  className="pl-7 w-full min-w-[180px]"
                />
              </TableCell>
              <TableCell />
            </TableRow>
            {filterResults.map((result) => (
              <EditSummaryCovariateShiftRow
                key={result.id}
                result={result}
                onResultSelect={(enabled) => onResultSelect(getResultRef(result), result.id, enabled)}
              />
            ))}
          </NoResultPlaceholder>
        </TableBody>
      </Table>
    </div>
  );
};

const EditSummaryCovariateShiftRow = ({
  result,
  onResultSelect,
}: {
  result: TimeSeriesResultSummary;
  onResultSelect: (enabled: boolean) => void;
}) => {
  const labels = getResultLabels(result);
  return (
    <TableRow>
      <TableCell className="px-4">
        {result.analysisType === AnalysisType.SummaryStats ? labels.analysis : labels.calculator}
      </TableCell>
      <TableCell className="px-4">{labels.metric}</TableCell>
      <TableCell className="px-4">{result.columnName}</TableCell>
      <TableCell className="text-center align-middle">
        <Checkbox
          onCheckedChange={(state) => onResultSelect(Boolean(state))}
          checked={result.tags.includes(SUMMARY_TAG_NAME) ?? false}
        />
      </TableCell>
    </TableRow>
  );
};

export const EditSummaryDataQuality = ({
  modelId,
  results: resultsFragment,
  onResultSelect,
}: {
  modelId: number;
  results: FragmentType<typeof summaryResultsFragment>[];
  onResultSelect: (resultRef: ResultRefInput, resultId: string, selected: boolean) => void;
}) => {
  const results = useAvailableResults(resultsFragment);
  const [metricFilter, setMetricFilter] = useState<string>("");
  const { search, setSearch, results: columnResults } = useSearchList(results, (r) => r.columnName ?? "");
  const metrics = useMemo(() => _.uniq(results.map((r) => r.metricName)), [results]);

  const filterResults = metricFilter
    ? columnResults.filter((r) => r.metricName === metricFilter.toLowerCase())
    : columnResults;

  return (
    <div className="flex flex-col gap-6 w-min">
      <Heading view={ResultView.DataQuality} modelId={modelId} />
      <Table className="whitespace-nowrap">
        <TableHeader className="before:absolute before:inset-0 before:-top-6 before:backdrop-blur">
          <TableRow>
            <TableHead>Metric</TableHead>
            <TableHead>Column name</TableHead>
            <TableHead className="text-center">Show in summary</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          <NoResultPlaceholder results={results}>
            <TableRow>
              <TableCell className="relative">
                <FilterIcon
                  size={16}
                  strokeWidth={1.5}
                  className="absolute text-slate-400 top-1/2 left-5 transform -translate-y-1/2"
                />
                <Select
                  className="pl-7 min-w-[180px]"
                  placeholder="Filter method"
                  value={metricFilter}
                  onValueChange={setMetricFilter}
                  onClear={() => setMetricFilter("")}
                >
                  {metrics.map((metric) => (
                    <SelectItem key={metric} value={metric}>
                      {dataQualityMetricLabels[metric.toUpperCase() as DataQualityMetric]}
                    </SelectItem>
                  ))}
                </Select>
              </TableCell>
              <TableCell className="relative">
                <FilterIcon
                  size={16}
                  strokeWidth={1.5}
                  className="absolute text-slate-400 top-1/2 left-5 transform -translate-y-1/2"
                />
                <InputWithClearButton
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Filter column name"
                  className="pl-7 w-full min-w-[180px]"
                />
              </TableCell>
              <TableCell />
            </TableRow>
            {filterResults.map((result) => (
              <EditSummaryDataQualityRow
                key={result.id}
                result={result}
                onResultSelect={(enabled) => onResultSelect(getResultRef(result), result.id, enabled)}
              />
            ))}
          </NoResultPlaceholder>
        </TableBody>
      </Table>
    </div>
  );
};

const EditSummaryDataQualityRow = ({
  result,
  onResultSelect,
}: {
  result: TimeSeriesResultSummary;
  onResultSelect: (enabled: boolean) => void;
}) => {
  const labels = getResultLabels(result);
  return (
    <TableRow>
      <TableCell className="px-4">{labels.metric}</TableCell>
      <TableCell className="px-4">{result.columnName}</TableCell>
      <TableCell className="text-center align-middle">
        <Checkbox
          onCheckedChange={(state) => onResultSelect(Boolean(state))}
          checked={result.tags.includes(SUMMARY_TAG_NAME) ?? false}
        />
      </TableCell>
    </TableRow>
  );
};
