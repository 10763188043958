import { ReactNode } from "react";

import { Tooltip, TooltipContent, TooltipTrigger } from "@/DesignSystem/shadcn/Tooltip/Tooltip";

export const SimpleTooltip = ({
  children,
  tooltipContent,
  side,
}: {
  children: ReactNode;
  tooltipContent: ReactNode;
  side?: "top" | "bottom" | "left" | "right";
}) => {
  return (
    <Tooltip>
      <TooltipTrigger asChild>{children}</TooltipTrigger>
      <TooltipContent className={"data-[side=right]:slide-in-from-left-1"} side={side ?? "bottom"}>
        {tooltipContent}
      </TooltipContent>
    </Tooltip>
  );
};
