import { useApolloClient, useMutation } from "@apollo/client";
import { ResultOf } from "@graphql-typed-document-node/core";
import _ from "lodash";
import { InfoIcon, LoaderCircleIcon, SaveIcon, XIcon } from "lucide-react";
import { createContext, useContext, useEffect, useRef, useState } from "react";

import {
  EditModelInput,
  FragmentType,
  MonitoringRuntimeConfigDetailsFragment,
  RuntimeConfigInput,
  gql,
  useFragment,
} from "@/apis/nannyml";
import { confirm } from "@/components/Dialog";
import { Button } from "@/components/common/Button";
import { resolveWithToast, toast } from "@/components/common/Toast/useToast";
import { useRuntimeConfig } from "@/components/monitoring/RuntimeConfig";
import { cn } from "@/lib/utils";

const monitoringModelSettingsFragment = gql(/* GraphQL */ `
  fragment MonitoringModelSettings on Model {
    id
    problemType
    ...MonitoringPerformanceConfig
    ...MonitoringModelDetails
    ...MonitoringModelDataSources
    runtimeConfig {
      ...MonitoringRuntimeConfigDetails
    }
    segments {
      id
      segmentColumnName
      segment
    }
    ...MonitoringModelSchedule
  }
`);

const editMonitoringModel = gql(/* GraphQL */ `
  mutation EditMonitoringModel($input: EditModelInput!) {
    edit_monitoring_model(input: $input) {
      ...MonitoringModelSettings
    }
  }
`);

const modelEditorContext = createContext<null | {
  key: number;
  isValid: boolean;
  hasChanges: boolean;
  busy: boolean;
  config: MonitoringRuntimeConfigDetailsFragment;
  unchangedModel: ResultOf<typeof monitoringModelSettingsFragment>;
  model: Omit<ResultOf<typeof monitoringModelSettingsFragment>, "runtimeConfig"> & {
    runtimeConfig: RuntimeConfigInput;
  };
  onModelChange: (changes: Partial<EditModelInput>) => void;
  onRuntimeConfigChange: (changes: Partial<RuntimeConfigInput>) => void;
  onCommit: () => Promise<void>;
  onDiscard: () => void;
}>(null);

export type MonitoringModelSettingsFragment = ResultOf<typeof monitoringModelSettingsFragment>;

export const ModelEditor = ({
  className,
  model: modelFragment,
  children,
}: {
  className?: string;
  model: FragmentType<typeof monitoringModelSettingsFragment>;
  children: React.ReactNode;
}) => {
  const client = useApolloClient();
  const model = useFragment(monitoringModelSettingsFragment, modelFragment);
  const [config, runtimeConfigInput] = useRuntimeConfig(model.runtimeConfig);
  const [stagedChanges, setStagedChanges] = useState<EditModelInput>({ modelId: model.id });
  const [isValid, setIsValid] = useState(true);
  const [key, setKey] = useState(0); // Provides a key that can be used to reset components after model change
  const formRef = useRef<HTMLFormElement>(null);
  const [editModel, { loading }] = useMutation(editMonitoringModel, {
    onCompleted: (data) => {
      // Reset staged changes & clear result cache if the mutation was successful
      if (data.edit_monitoring_model.__typename === "Model") {
        onDiscard();
        client.cache.evict({ id: `Model:${model.id}`, fieldName: "results" });
        setKey((key) => key + 1);
      }
    },
  });

  const onCommit = () => {
    const promise = editModel({ variables: { input: stagedChanges } }).then(({ data }) =>
      data?.edit_monitoring_model.__typename === "ResultInvalidationRequired"
        ? confirm({
            title: "Changes require invalidation",
            message: (
              <div className="grid grid-cols-[auto_1fr] gap-4">
                <InfoIcon size={36} className="row-span-2" />
                <p>
                  The changes you made require invalidation of the results. This means the next NannyML run will perform
                  fitting and analyse all data. This may take a long time and uses additional compute.
                </p>
                <p>Do you want to proceed?</p>
              </div>
            ),
          }).then<any>((accepted) => {
            if (accepted) {
              return editModel({ variables: { input: { ...stagedChanges, allowInvalidatingResults: true } } });
            }
          })
        : data?.edit_monitoring_model.__typename === "Model"
        ? Promise.resolve()
        : Promise.reject("Failed to update model settings")
    );
    resolveWithToast(promise, {
      title: "Saving model settings...",
      successDescription: "✅ Model settings saved",
      errorDescription: (error) => `❌ Model settings couldn't be saved: ${error.message}`,
      retry: onCommit,
    });
    return promise;
  };

  const onDiscard = () => setStagedChanges({ modelId: model.id });

  useEffect(() => {
    setTimeout(() => {
      if (formRef?.current) {
        setIsValid(formRef.current.checkValidity());
      }
    }, 1);
  }, [stagedChanges]);

  const ctx = {
    key,
    isValid,
    hasChanges: Object.keys(stagedChanges).length > 1,
    busy: loading,
    config,
    unchangedModel: model,
    model: {
      ...model,
      ...stagedChanges,
      runtimeConfig: { ...runtimeConfigInput, ...stagedChanges.runtimeConfig } as RuntimeConfigInput,
    },
    onModelChange: (changes: Partial<EditModelInput>) => setStagedChanges((prev) => ({ ...prev, ...changes })),
    onRuntimeConfigChange: (changes: Partial<RuntimeConfigInput>) =>
      setStagedChanges((prev) => ({ ...prev, runtimeConfig: { ...prev.runtimeConfig, ...changes } })),
    onCommit,
    onDiscard,
  };

  return (
    <modelEditorContext.Provider value={ctx}>
      <form ref={formRef} className={className}>
        {children}
      </form>
    </modelEditorContext.Provider>
  );
};

export const useModelEditor = () => {
  const ctx = useContext(modelEditorContext);
  if (!ctx) {
    throw new Error("useModelEditor can only be used inside a ModelEditor");
  }
  return ctx;
};

export const EditModelControls = ({ className }: { className?: string }) => {
  const { busy, hasChanges, isValid, onCommit, onDiscard } = useModelEditor();
  return (
    <div className={cn("flex justify-center gap-4", className)}>
      <Button
        cva={{ intent: "primary", size: "mediumLong" }}
        disabled={busy || !hasChanges || !isValid}
        onClick={onCommit}
      >
        {busy ? <LoaderCircleIcon className="animate-spin" size={20} /> : <SaveIcon size={20} />}
        Save changes
      </Button>
      <Button cva={{ intent: "reject", size: "mediumLong" }} disabled={busy || !hasChanges} onClick={onDiscard}>
        <XIcon size={20} />
        Discard changes
      </Button>
    </div>
  );
};

export const Title = ({ className, children }: { className?: string; children: React.ReactNode }) => (
  <h3 className={cn("text-2xl font-semibold pb-1 border-b border-gray-600", className)}>{children}</h3>
);

export const Subtitle = ({ className, children }: { className?: string; children: React.ReactNode }) => (
  // Intended to be used in a flex column container with gap-4
  <span className={cn("text-sm text-gray-400 -mt-2 mb-2", className)}>{children}</span>
);
