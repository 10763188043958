import { TableCell, TableHead } from "@/components/Table";
import * as ThresholdInput from "@/components/ThresholdInput";

export const ThresholdConfigCells = ({
  config,
  ...rest
}: Omit<ThresholdInput.SegmentThresholdInputProps, "segmentId"> & {
  segmentId?: number;
  config: { lowerValueLimit: number | null; upperValueLimit: number | null };
}) => (
  <ThresholdInput.SegmentRoot segmentId={rest.segmentId} {...rest}>
    <TableCell>
      <ThresholdInput.Type />
    </TableCell>
    <TableCell>
      <ThresholdInput.Value min={config.lowerValueLimit ?? undefined} max={config.upperValueLimit ?? undefined} />
    </TableCell>
    {Object.hasOwn(rest, "segmentId") && (
      <TableCell className="w-full">
        <div className="flex items-center gap-2">
          <ThresholdInput.SegmentInfo />
          <ThresholdInput.ClearSegmentOverrideButton />
        </div>
      </TableCell>
    )}
  </ThresholdInput.SegmentRoot>
);

export const ThresholdConfigHeaderCells = ({ useSegments }: { useSegments?: boolean }) => (
  <>
    <TableHead className="whitespace-nowrap">Threshold type</TableHead>
    <TableHead className="whitespace-nowrap">Threshold value</TableHead>
    {useSegments && <TableHead />}
  </>
);
