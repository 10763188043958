import { Input } from "@/DesignSystem/shadcn/Input";
import { ColumnInput, ColumnType } from "@/apis/nannyml";
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from "@/components/Table";
import { InformationModalChip } from "@/components/dashboard/InformationModal/InformationModalChip";

export const ClassMapping = ({
  schema,
  onSchemaChange,
}: {
  schema: ColumnInput[];
  onSchemaChange: (schema: ColumnInput[]) => void;
}) => (
  <div className="flex flex-col gap-4">
    <Table>
      <TableCaption className="caption-top text-left text-gray-400 mb-4">
        Map predicted probability columns to the matching classes
      </TableCaption>
      <TableHeader>
        <TableRow>
          <TableHead>Column name</TableHead>
          <TableHead className="flex">
            Prediction class name <InformationModalChip infoName="Prediction class name" />
          </TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {schema
          .filter((col) => col.columnType === ColumnType.PredictionScore)
          .map((col) => (
            <TableRow key={col.name}>
              <TableCell>{col.name}</TableCell>
              <TableCell>
                <Input
                  value={col.className ?? ""}
                  onChange={(e) => {
                    onSchemaChange(schema.map((c) => (c.name === col.name ? { ...c, className: e.target.value } : c)));
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  </div>
);
