import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

import { gql } from "@/apis/nannyml";

const getModelNameQuery = gql(/* GraphQL */ `
  query GetModelName($modelId: Int!) {
    monitoring_model(id: $modelId) {
      name
    }
  }
`);

export const MonitoringModelName = () => {
  const { modelId } = useParams();
  const { data, loading } = useQuery(getModelNameQuery, {
    variables: { modelId: parseInt(modelId!) },
  });

  if (loading) {
    return <span className="animate-pulse rounded w-16"></span>;
  }

  return <>{data?.monitoring_model?.name}</>;
};
