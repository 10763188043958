import * as Collapsible from "@radix-ui/react-collapsible";
import { ChevronsLeftIcon, ChevronsRightIcon } from "lucide-react";
import { PropsWithChildren, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { cn } from "@/lib/utils";

import { Button } from "./common/Button";

export const Navigation = ({ className, children }: PropsWithChildren<{ className?: string }>) => {
  const [isOpen, setIsOpen] = useState(true);
  const PanelIcon = isOpen ? ChevronsLeftIcon : ChevronsRightIcon;

  return (
    <Collapsible.Root
      className={cn("relative flex flex-col h-full py-4 gap-4 overflow-auto", className)}
      open={isOpen}
      onOpenChange={setIsOpen}
    >
      <Collapsible.Trigger asChild>
        <Button className="absolute top-3 right-3 p-1" cva={{ intent: "icon", size: "chip" }}>
          <PanelIcon size={20} />
        </Button>
      </Collapsible.Trigger>
      {children}
    </Collapsible.Root>
  );
};

export const NavGroup = ({ title, children }: { title: string; children: React.ReactNode }) => (
  <div className="flex flex-col">
    <div className="h-6 px-4">
      <Collapsible.Content className="uppercase text-sm text-gray-400 select-none">{title}</Collapsible.Content>
    </div>
    {children}
  </div>
);

export const NavItem = ({
  Icon,
  label,
  link,
}: {
  Icon: React.FC<{ size?: string | number }>;
  label: string;
  link: string;
}) => {
  const location = useLocation();
  const ariaCurrent = location.pathname === link ? "page" : undefined;

  return (
    <Link
      to={link}
      className={cn(
        "h-10 flex items-center gap-4 px-4 py-2 aria-[current]:text-highlightDeep",
        "hover:bg-slate-700 hover:text-highlightDeep"
      )}
      aria-current={ariaCurrent}
      title={label}
    >
      <Icon size={20} />
      <Collapsible.Content>{label}</Collapsible.Content>
    </Link>
  );
};
