import { useSuspenseQuery } from "@apollo/client";
import * as Collapsible from "@radix-ui/react-collapsible";
import {
  ChevronsLeftIcon,
  ChevronsRightIcon,
  LoaderCircleIcon,
  SettingsIcon,
  SheetIcon,
  TrendingUpIcon,
} from "lucide-react";
import { ReactNode, Suspense, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { gql } from "@/apis/nannyml";
import { Button } from "@/components/common/Button";
import { experimentTypeLabels } from "@/formatters/experiment";
import { useExperimentId } from "@/hooks/experiment";
import { cn } from "@/lib/utils";

const getExperimentMetadataQuery = gql(/* GraphQL */ `
  query GetExperimentMetadata($id: Int!) {
    experiment(id: $id) {
      id
      name
      experimentType
    }
  }
`);

export const ExperimentContainer = ({ children }: { children: React.ReactNode }) => {
  const experimentId = useExperimentId();
  const [isNavExpanded, setIsNavExpanded] = useState(true);
  const PanelIcon = isNavExpanded ? ChevronsLeftIcon : ChevronsRightIcon;

  return (
    <div className="flex-grow flex h-full">
      <Collapsible.Root
        className="flex-shrink-0 flex flex-col py-2 overflow-auto border-r border-gray-600 group"
        open={isNavExpanded}
        onOpenChange={setIsNavExpanded}
      >
        <div className="flex items-center px-4 pb-1 border-b border-gray-600">
          <Collapsible.Content className="flex-grow">
            <Suspense fallback={<NavHeaderSkeleton />}>
              <NavHeader experimentId={experimentId} />
            </Suspense>
          </Collapsible.Content>
          <Collapsible.Trigger asChild>
            <Button cva={{ intent: "icon", size: "chip" }} className="p-1">
              <PanelIcon size={20} />
            </Button>
          </Collapsible.Trigger>
        </div>
        <NavHeading>Experiment evaluation</NavHeading>
        <NavItem link={`/experiment/${experimentId}`} Icon={SheetIcon} label="Summary" />
        <NavItem link={`/experiment/${experimentId}/metrics`} Icon={TrendingUpIcon} label="Experiment metrics" />
        <NavHeading>Management</NavHeading>
        <NavItem link={`/experiment/${experimentId}/settings`} Icon={SettingsIcon} label="Settings" />
      </Collapsible.Root>
      <div className="flex-grow h-full overflow-auto">
        <Suspense fallback={<ContentSkeleton />}>{children}</Suspense>
      </div>
    </div>
  );
};

const NavHeading = ({ children }: { children: ReactNode }) => (
  <div className="border-t border-transparent group-data-[state='closed']:border-gray-500">
    <Collapsible.Content className="uppercase text-sm text-gray-400 px-4 pt-4 pb-1 cursor-default">
      {children}
    </Collapsible.Content>
  </div>
);

const NavItem = ({
  Icon,
  label,
  link,
}: {
  Icon: React.FC<{ size?: string | number }>;
  label: string;
  link: string;
}) => {
  const location = useLocation();
  const ariaCurrent = location.pathname === link ? "page" : undefined;

  return (
    <Link
      to={link}
      className={cn(
        "flex items-center gap-4 px-4 py-2 aria-[current]:text-highlightDeep",
        "hover:bg-slate-700 hover:text-highlightDeep"
      )}
      aria-current={ariaCurrent}
      title={label}
    >
      <Icon size={20} />
      <Collapsible.Content>{label}</Collapsible.Content>
    </Link>
  );
};

const NavHeader = ({ experimentId }: { experimentId: number }) => {
  const {
    data: { experiment },
  } = useSuspenseQuery(getExperimentMetadataQuery, { variables: { id: experimentId } });

  if (!experiment) {
    throw new Error(`Experiment '${experimentId}' not found`);
  }

  return (
    <>
      <div className="text-ellipsis overflow-hidden max-w-[min(20vw,20ch)]" title={experiment.name}>
        {experiment.name}
      </div>
      <div className="text-sm text-gray-400">{experimentTypeLabels[experiment.experimentType]}</div>
    </>
  );
};

const NavHeaderSkeleton = () => (
  <div className="space-y-2">
    <div className="w-[22ch] h-5 bg-gray-500 animate-pulse rounded" />
    <div className="w-[14ch] h-4 bg-gray-500 animate-pulse rounded" />
  </div>
);

const ContentSkeleton = () => (
  <div className="h-full w-full flex gap-2 items-center justify-center text-gray-400">
    <LoaderCircleIcon className="animate-spin text-highlightDeep" size={24} />
    <p>Loading the latest data for you...</p>
  </div>
);
