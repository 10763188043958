import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

export type DateLike = Date | string | number;
export type GenericDate = string | number | Date | dayjs.Dayjs;

export const middleDate = (lowerDate: GenericDate, upperDate: GenericDate) => {
  return dayjs((dayjs(lowerDate).valueOf() + dayjs(upperDate).valueOf()) / 2);
};

export const getDateDiffMS = (lowerDate: GenericDate, upperDate: GenericDate) => {
  return dayjs(upperDate).diff(dayjs(lowerDate));
};

export const addMS = (baseDate: GenericDate, ms: number) => {
  return dayjs(baseDate).add(ms, "milliseconds");
};

export const DateFormatWithSeconds = "YYYY-MM-DDTHH:mm:ss";

export const DateFormatWithMinutes = "YYYY-MM-DD HH:mm";

export const DateFormatWithDay = "YYYY-MM-DD";

export const formatWith = (date: Date | string | number, format: string) => dayjs(date).format(format);

const timeDeltaFormatters: [number, string][] = [
  [1000 * 60 * 60 * 24 * 365, "year"],
  [1000 * 60 * 60 * 24 * 30, "month"],
  [1000 * 60 * 60 * 24 * 7, "week"],
  [1000 * 60 * 60 * 24, "day"],
  [1000 * 60 * 60, "hour"],
  [1000 * 60, "minute"],
  [1000, "second"],
];

const relativeTimeFormatter = new Intl.RelativeTimeFormat();

export const formatTimeDelta = (date: Date | string | number, refDate?: Date | string | number) => {
  // Preprocess arguments
  if (!(date instanceof Date)) {
    date = new Date(date);
  }
  if (refDate === undefined) {
    refDate = new Date();
  } else if (!(refDate instanceof Date)) {
    refDate = new Date(refDate);
  }

  const delta = date.getTime() - refDate.getTime();
  const [ms, unit] = timeDeltaFormatters.find(([ms]) => Math.abs(delta) >= ms) ?? [1000, "second"];

  // Return formatted delta
  return relativeTimeFormatter.format(Math.round(delta / ms), unit as Intl.RelativeTimeFormatUnit);
};

const datetimeFormatter = new Intl.DateTimeFormat(undefined, { dateStyle: "medium", timeStyle: "short" });
export const formatDateTime = (date: Date | string | number) => datetimeFormatter.format(new Date(date));

const dateFormatter = new Intl.DateTimeFormat(undefined, { dateStyle: "medium" });
export const formatDate = (date: Date | string | number) => dateFormatter.format(new Date(date));
