import {
  AlignLeftIcon,
  BarChart4Icon,
  ChevronsLeft,
  ChevronsRight,
  FunctionSquareIcon,
  LucideIcon,
  SettingsIcon,
  SheetIcon,
  TrendingUp,
  FileCheckIcon,
} from "lucide-react";
import { Link } from "react-router-dom";

import { Text } from "@/DesignSystem/basic/Text/Text";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/DesignSystem/shadcn/Tooltip/Tooltip";
import { ProblemTypeOptions, TypeProblemTypeOptions } from "@/domains/model/modelOptions";
import { ResultView } from "@/domains/monitoring";
import { ProblemTypeNames } from "@/formatters/models/modelTexts";
import { resultViewLabels } from "@/formatters/monitoring";
import { cn } from "@/lib/utils";

const MonitoringLinks = [
  {
    name: "Summary",
    icon: SheetIcon,
    target: "summary",
  },
  {
    name: resultViewLabels[ResultView.Performance],
    icon: TrendingUp,
    target: ResultView.Performance,
  },
  {
    name: resultViewLabels[ResultView.ConceptDrift],
    icon: FunctionSquareIcon,
    target: ResultView.ConceptDrift,
    shouldHide: (problemType: TypeProblemTypeOptions) => {
      return problemType !== ProblemTypeOptions.BinaryClassification;
    },
  },
  {
    name: resultViewLabels[ResultView.CovariateShift],
    icon: BarChart4Icon,
    target: ResultView.CovariateShift,
  },
  {
    name: resultViewLabels[ResultView.DataQuality],
    icon: FileCheckIcon,
    target: ResultView.DataQuality,
  },
];

const ManagementLinks = [
  // {
  //   name: "Monitoring status",
  //   icon: MonitorIcon,
  // },
  {
    name: "Logs",
    icon: AlignLeftIcon,
    target: "runs",
  },
  {
    name: "Settings",
    icon: SettingsIcon,
    target: "settings",
  },
];

export const LinksGroup = ({
  modelId,
  isCollapsed,
  groupName,
  pathName,
  links,
  onClickLink,
}: {
  modelId: number;
  groupName: string;
  pathName: string;
  isCollapsed: boolean;
  links: { name: string; icon: LucideIcon; target: string }[];
  onClickLink?: () => void;
}) => {
  const hiddenOnCollapsedClass = isCollapsed ? "hidden" : "block";
  const invisibleOnCollapsedClass = isCollapsed ? "invisible" : "visible";

  const iconSize = 20;
  const iconWrapperClassName = cn(
    "flex items-center gap-2 py-3 px-2 hover:text-highlightDeep hover:bg-deepBg",
    isCollapsed ? "" : ""
  );

  return (
    <div className={"fcol"}>
      <div>
        <Text size={"small"} className={cn("h-[1.15rem] px-2", invisibleOnCollapsedClass)}>
          {isCollapsed ? "0" : groupName}
        </Text>
      </div>

      <div className={"fcol"}>
        {links.map((link) => {
          const target = `/monitoring/model/${modelId}/${link.target}`;
          const isSelected = pathName.startsWith(target);

          return (
            <Tooltip key={link.name}>
              <TooltipTrigger asChild>
                <Link
                  to={target}
                  className={cn(iconWrapperClassName, isSelected ? "text-highlightDeep/75" : "")}
                  onClick={onClickLink}
                >
                  <link.icon size={iconSize} />
                  <Text intent={"link"} className={hiddenOnCollapsedClass}>
                    {link.name}
                  </Text>
                </Link>
              </TooltipTrigger>

              <TooltipContent className={isCollapsed ? "" : "hidden"} side={"right"}>
                {link.name}
              </TooltipContent>
            </Tooltip>
          );
        })}
      </div>
    </div>
  );
};

export const ModelNavBar = ({
  isOverlay,
  divId,
  modelId,
  modelName,
  problemType,
  pathName,
  isCollapsed,
  onCollapseNavBar,
  onExpandNavBar,
}: {
  divId?: string;
  modelId: number;
  modelName: string;
  problemType: TypeProblemTypeOptions;
  pathName: string;
  isCollapsed: boolean;
  onCollapseNavBar?: () => void;
  onExpandNavBar?: () => void;
  isOverlay?: boolean;
}) => {
  return (
    <div
      id={isOverlay ? undefined : divId}
      className={cn(
        "@container fcol flex-shrink-0 border-r border-gray-600 py-2",
        isCollapsed ? "basis-[40px]" : "basis-[200px]"
      )}
    >
      <div className={cn(isCollapsed ? "hidden" : "flex", "px-2 items-center justify-between gap-2")}>
        <div className="min-w-0">
          <div className="overflow-hidden text-ellipsis w-full" title={modelName}>
            {modelName}
          </div>
          <Text size={"xSmall"} className="text-gray-400">
            {ProblemTypeNames[problemType]}
          </Text>
        </div>

        <button onClick={onCollapseNavBar}>
          <ChevronsLeft size={20} />
        </button>
      </div>

      <div
        className={cn(isCollapsed ? "block" : "hidden", "h-[60px]")}
        onMouseEnter={isOverlay ? onExpandNavBar : undefined}
      >
        <div className={"flex items-center justify-center h-full"}>
          <div>
            <button onClick={onExpandNavBar}>
              <ChevronsRight size={20} />
            </button>
          </div>
        </div>
      </div>

      <div className={"border-b border-b-placeholder pt-1"} />

      <div className={"fcol gap-8 pt-2"}>
        <LinksGroup
          modelId={modelId}
          groupName={"Monitoring"}
          pathName={pathName}
          isCollapsed={isCollapsed!}
          links={MonitoringLinks.filter((link) => {
            return link.shouldHide ? !link.shouldHide(problemType) : true;
          })}
          onClickLink={isOverlay ? onCollapseNavBar : undefined}
        />

        <LinksGroup
          modelId={modelId}
          groupName={"Management"}
          pathName={pathName}
          isCollapsed={isCollapsed!}
          links={ManagementLinks}
          onClickLink={isOverlay ? onCollapseNavBar : undefined}
        />
      </div>
    </div>
  );
};
