import {
  BanIcon,
  CircleCheckIcon,
  CircleDotIcon,
  CircleXIcon,
  ClockIcon,
  FingerprintIcon,
  GroupIcon,
  HelpCircleIcon,
  ListOrderedIcon,
  LucideIcon,
  TagIcon,
  TargetIcon,
  TextIcon,
} from "lucide-react";

import { ColumnFlag, ColumnType, ProblemType } from "@/apis/nannyml";
import { ObjectValues } from "@/constants/enums";

import { ColumnConfig } from "./common";

export enum CalculatorGroup {
  Performance = "PERFORMANCE",
  CovariateShift = "COVARIATE_SHIFT",
  ConceptDrift = "CONCEPT_DRIFT",
  DescriptiveStatistics = "DESCRIPTIVE_STATISTICS",
  DataQuality = "DATA_QUALITY",
}

export enum ResultView {
  Performance = "performance",
  CovariateShift = "covariate-shift",
  ConceptDrift = "concept-shift",
  DataQuality = "data-quality",
}

export type LastRunDetails = {
  lastRunSkipped?: boolean | null;
  lastRunErrorsCount?: number | null;
  lastRunStartDate?: string | null;
  lastRunEndDate?: string | null;
  lastDataPointDate?: string | null;
};

export type ModelOverview = {
  modelId: number;
  statuses: ModelOverviewStatuses;
  alerts?: ModelOverviewAlerts;
  progress?: ModelOverviewProgress;
};

export type ModelOverviewStatuses = {
  alertsStatus: "alerts" | "ok" | "idle";
  currentRunStatus?: "running" | "ok" | "idle";
  stableRunCompletionStatus?: "errors" | "ok" | "idle";
};

export type ModelOverviewAlerts = {
  allPerformance: number;
  targetDrift?: number;
  outputDrift: number;
  allDrift: number;
};

export type ModelOverviewProgress = {
  total: number;
  completed: number;
  description?: string;
};

export const RunStatusOptions = {
  COMPLETED: "OK",
  RUNNING: "RUNNING",
  CANCELLING: "CANCELLING",
  IDLE: "IDLE",
} as const;

export type TypeRunStatusOptions = ObjectValues<typeof RunStatusOptions>;

export const problemTypeColumns: Record<ProblemType, ColumnConfig> = {
  [ProblemType.BinaryClassification]: {
    types: [
      { type: ColumnType.Timestamp, important: true },
      { type: ColumnType.Identifier, important: true },
      { type: ColumnType.Target, important: true },
      { type: ColumnType.Prediction, optional: true, important: true },
      { type: ColumnType.PredictionScore, important: true },
      { type: ColumnType.ContinuousFeature, optional: true, allowMultiple: true },
      { type: ColumnType.CategoricalFeature, optional: true, allowMultiple: true },
      { type: ColumnType.Ignored, optional: true, allowMultiple: true },
    ],
    flags: [{ flag: ColumnFlag.Segment, important: true }],
  },
  [ProblemType.MulticlassClassification]: {
    types: [
      { type: ColumnType.Timestamp, important: true },
      { type: ColumnType.Identifier, important: true },
      { type: ColumnType.Target, important: true },
      { type: ColumnType.Prediction, important: true },
      { type: ColumnType.PredictionScore, allowMultiple: true, important: true },
      { type: ColumnType.ContinuousFeature, optional: true, allowMultiple: true },
      { type: ColumnType.CategoricalFeature, optional: true, allowMultiple: true },
      { type: ColumnType.Ignored, optional: true, allowMultiple: true },
    ],
    flags: [{ flag: ColumnFlag.Segment, important: true }],
  },
  [ProblemType.Regression]: {
    types: [
      { type: ColumnType.Timestamp, important: true },
      { type: ColumnType.Identifier, important: true },
      { type: ColumnType.Target, important: true },
      { type: ColumnType.Prediction, important: true },
      { type: ColumnType.ContinuousFeature, optional: true, allowMultiple: true },
      { type: ColumnType.CategoricalFeature, optional: true, allowMultiple: true },
      { type: ColumnType.Ignored, optional: true, allowMultiple: true },
    ],
    flags: [{ flag: ColumnFlag.Segment, important: true }],
  },
};

export const columnTypeIcons: Record<ColumnType, LucideIcon> = {
  [ColumnType.CategoricalFeature]: TextIcon,
  [ColumnType.ContinuousFeature]: ListOrderedIcon,
  [ColumnType.Identifier]: FingerprintIcon,
  [ColumnType.Ignored]: BanIcon,
  [ColumnType.Timestamp]: ClockIcon,
  [ColumnType.Prediction]: CircleDotIcon,
  [ColumnType.PredictionScore]: HelpCircleIcon,
  [ColumnType.Target]: TargetIcon,
  [ColumnType.MetricName]: TagIcon,
  [ColumnType.GroupName]: GroupIcon,
  [ColumnType.SuccessCount]: CircleCheckIcon,
  [ColumnType.FailCount]: CircleXIcon,
};

export const columnFlagIcons: Record<ColumnFlag, LucideIcon> = {
  [ColumnFlag.Segment]: GroupIcon,
};

export const supportsConceptDrift = (problemType: ProblemType) => problemType === ProblemType.BinaryClassification;
