import { useMutation } from "@apollo/client";
import { ResultOf } from "@graphql-typed-document-node/core";
import _ from "lodash";
import { Loader2Icon, PlusIcon, UploadIcon } from "lucide-react";
import React from "react";

import { FragmentType, StorageInput, gql, useFragment } from "@/apis/nannyml";
import { DatasetStorageDetails } from "@/components/DatasetStorage";
import { Dialog, DialogContent, DialogTrigger } from "@/components/Dialog";
import { LabeledField } from "@/components/LabeledField";
import { Button } from "@/components/common/Button";
import { cn } from "@/lib/utils";

const dataSourceDetailsFragment = gql(/* GraphQL */ `
  fragment DataSourceDetails on DataSource {
    id
    name
    hasReferenceData
    nrRows
    columns {
      name
      columnType
      columnFlags
      dataType
      className
    }
    head
  }
`);

const addDataToDataSourceMutation = gql(/* GraphQL */ `
  mutation AddDataToDataSource($input: DataSourceDataInput!) {
    add_data_to_data_source(input: $input) {
      ...DataSourceDetails
    }
  }
`);

const rowCountFormatter = new Intl.NumberFormat();

type UseDataSourceDetails = {
  (fragment: FragmentType<typeof dataSourceDetailsFragment>): ResultOf<typeof dataSourceDetailsFragment>;
  (fragment: FragmentType<typeof dataSourceDetailsFragment>[]): ResultOf<typeof dataSourceDetailsFragment>[];
};

export const useDataSourceDetails: UseDataSourceDetails = (fragment) => fragment as any;

export const DataSourceOverview = ({
  className,
  dataSources: dataSourcesFragment,
}: {
  dataSources: FragmentType<typeof dataSourceDetailsFragment>[];
  className?: string;
}) => {
  const dataSources = useFragment(dataSourceDetailsFragment, dataSourcesFragment);
  return (
    <div className={cn("grid grid-rows-[repeat(2,auto)] grid-flow-col w-fit gap-x-12 gap-y-2", className)}>
      {dataSources.map((dataSource) => (
        <React.Fragment key={dataSource.id}>
          <LabeledField key={dataSource.id} label={`${_.capitalize(dataSource.name)} data`}>
            {rowCountFormatter.format(dataSource.nrRows ?? 0)} rows
          </LabeledField>
          {dataSource.hasReferenceData ? <div /> : <AddDataToDataSourceDialog dataSource={dataSource} />}
        </React.Fragment>
      ))}
    </div>
  );
};

const AddDataToDataSourceDialog = ({ dataSource }: { dataSource: ResultOf<typeof dataSourceDetailsFragment> }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button cva={{ intent: "action", size: "small", border: "thin" }}>
          <PlusIcon size={20} />
          Add new rows
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-[95%] max-h-[95%] w-fit overflow-auto">
        <AddDataToDataSource dataSource={dataSource} onUploadCompleted={() => setIsOpen(false)} />
      </DialogContent>
    </Dialog>
  );
};

const AddDataToDataSource = ({
  dataSource,
  onUploadCompleted,
}: {
  dataSource: ResultOf<typeof dataSourceDetailsFragment>;
  onUploadCompleted?: () => void;
}) => {
  const ref = React.useRef<HTMLFormElement>(null);
  const [storageInfo, setStorageInfo] = React.useState<StorageInput | null>(null);
  const [isValid, setIsValid] = React.useState(false);
  const [uploadData, { loading, error }] = useMutation(addDataToDataSourceMutation);

  const onDataUpload = (e: React.FormEvent) => {
    e.preventDefault();

    uploadData({
      variables: {
        input: {
          id: dataSource.id,
          storageInfo: storageInfo!,
        },
      },
    }).then(() => onUploadCompleted?.());
  };

  React.useEffect(() => {
    setIsValid((storageInfo ?? false) && ref.current!.checkValidity());
  }, [storageInfo]);

  return (
    <form ref={ref} className="flex flex-col h-full justify-between items-center gap-8" onSubmit={onDataUpload}>
      <DatasetStorageDetails name={dataSource.name} value={storageInfo} onChange={setStorageInfo} />
      <div className="flex flex-col gap-4 items-center">
        <Button type="submit" disabled={loading || !isValid} cva={{ intent: "primary", size: "mediumLong" }}>
          {loading ? (
            <span className="animate-spin">
              <Loader2Icon size={20} />
            </span>
          ) : (
            <UploadIcon size={20} />
          )}
          Upload data
        </Button>
        {error && <span className="text-red-500 text-center">{error.message}</span>}
      </div>
    </form>
  );
};
