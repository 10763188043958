import { AggregationOptions } from "@/domains/chunking/chunkingOptions";
import { ProblemTypeOptions } from "@/domains/model/modelOptions";

export const AggregationOptionsNames = {
  [AggregationOptions.Daily]: "Day",
  [AggregationOptions.Weekly]: "Week",
  [AggregationOptions.Monthly]: "Month",
  [AggregationOptions.Quarter]: "Quarter",
  [AggregationOptions.Yearly]: "Year",
  [AggregationOptions.NumberOfRows]: "Number of rows",
};

export const ProblemTypeNames = {
  [ProblemTypeOptions.BinaryClassification]: "Binary classification",
  [ProblemTypeOptions.MulticlassClassification]: "Multiclass classification",
  [ProblemTypeOptions.Regression]: "Regression",
};
