import { useQuery } from "@apollo/client";
import { CheckCircle2, Copy, FileText, TriangleAlert, XCircle } from "lucide-react";
import React from "react";

import { SimpleTooltip } from "@/DesignSystem/nanny/SimpleTooltip/SimpleTooltip";
import { Run, RunEventType, RunState } from "@/apis/nannyml";
import { getModelRuns } from "@/apis/nannyml/queries/getModelRuns/getModelRuns";
import { Button } from "@/components/common/Button";
import { useToast } from "@/components/common/Toast/useToast";
import { RequestStateLayout } from "@/components/dashboard/RequestStateLayout/RequestStateLayout";
import { calculatorLabels } from "@/formatters/monitoring";
import { runEventTypeLabels } from "@/formatters/runs";
import { formatTimeDelta } from "@/lib/dateUtils";
import { useParamsModelId } from "@/routes/useParamsModelId";

const getEventIndicatorIcon = ({
  ranSuccessfully,
  eventType,
}: {
  ranSuccessfully?: boolean | null;
  eventType: RunEventType;
}) => {
  const iconSize = 28;

  if (eventType === RunEventType.Warning || eventType === RunEventType.InfrastructureWarning) {
    return <TriangleAlert className="text-yellow-500" size={iconSize} />;
  } else if (
    eventType === RunEventType.Error ||
    eventType === RunEventType.Timeout ||
    eventType === RunEventType.InfrastructureError
  ) {
    return <XCircle className="text-red-500" size={iconSize} />;
  } else if (eventType === RunEventType.Completed) {
    if (ranSuccessfully) {
      return <CheckCircle2 className="text-green-500" size={iconSize} />;
    } else {
      return <XCircle className="text-red-500" size={iconSize} />;
    }
  }
};

const getRunStatusText = (
  run: Pick<Run, "state" | "ranSuccessfully" | "startedAt" | "completedAt" | "scheduledFor">
) => {
  if (run.state == RunState.Completed) {
    if (run.ranSuccessfully) {
      return `Completed ${formatTimeDelta(run.completedAt)}`;
    } else {
      return `Completed with errors ${formatTimeDelta(run.completedAt)}`;
    }
  } else if (run.state == RunState.Cancelling) {
    return `Cancelled ${formatTimeDelta(run.startedAt)}`;
  } else if (run.state == RunState.Running) {
    return `Run started ${formatTimeDelta(run.startedAt)}`;
  } else if (run.state == RunState.Scheduled) {
    return `Scheduled ${formatTimeDelta(run.scheduledFor)}`;
  }
};

const wasSkipped = (run: Pick<Run, "log">) => run.log?.includes("No new data found. Skipping run.");

const summaryEventTypes = [
  RunEventType.Error,
  RunEventType.Timeout,
  RunEventType.Warning,
  RunEventType.InfrastructureError,
  RunEventType.InfrastructureWarning,
];

export const ModelRunOverview = () => {
  const { toast } = useToast();

  const modelId = useParamsModelId();
  const { data, loading, error } = useQuery(getModelRuns, {
    variables: { modelId },
  });

  if (loading || error) {
    return (
      <RequestStateLayout
        isLoading={loading}
        hasError={Boolean(error)}
        errorText={error?.message ? `Error: ${error.message}` : undefined}
      />
    );
  }

  const runs = data! // TODO: we should remove some of these nullable types...
    .monitoring_model!.runs.toSorted((a, b) => parseInt(b.id) - parseInt(a.id));

  return (
    <div role="list" className="grid grid-cols-[auto_1fr_auto] m-12 gap-y-24 gap-x-4">
      {runs.map((run) => (
        <React.Fragment key={run.id}>
          <div className="col-start-1 flex flex-col">
            <span className="font-semibold"># {run.id}</span>
            <span className="text-sm text-gray-500">{getRunStatusText(run)}</span>
          </div>
          <div>
            {run.state === RunState.Scheduled ? (
              <span>{`Run scheduled at ${new Date(run.scheduledFor).toLocaleString()}`}</span>
            ) : wasSkipped(run) ? (
              <span>Run was skipped, there was no new data.</span>
            ) : (
              <ol className="flex">
                {run.events
                  .filter(({ calculator, eventType }) => calculator || summaryEventTypes.includes(eventType))
                  .map((event) => (
                    <li className="mb-6" key={event.id}>
                      <div className="flex items-center">
                        {event.description ? (
                          <SimpleTooltip tooltipContent={<p className="max-w-prose">{event.description}</p>} side="top">
                            <div>{getEventIndicatorIcon(event)}</div>
                          </SimpleTooltip>
                        ) : (
                          <div>{getEventIndicatorIcon(event)}</div>
                        )}
                        <div className="w-full bg-gray-500 h-0.5" />
                      </div>
                      <div className="mt-3 pr-5">
                        <span className="text-sm font-semibold text-white">
                          {event.calculator ? calculatorLabels[event.calculator] : runEventTypeLabels[event.eventType]}
                        </span>
                        <div className="flex flex-row gap-x-2 py-1">
                          {/*<div className={"text-gray-500 py-1"}>*/}
                          {/*  <Clock size={16} />*/}
                          {/*</div>*/}
                          <p className="text-sm text-gray-500 dark:text-gray-400">
                            {new Date(event.timestamp).toLocaleString()}
                          </p>
                        </div>
                      </div>
                    </li>
                  ))}
              </ol>
            )}
          </div>
          <div className="px-4 sticky right-0 bg-dark">
            <Button
              cva={{ intent: "icon" }}
              title="View logs"
              onClick={() => document.getElementById(`logs-${run.id}`)?.classList.toggle("hidden")}
              className={run.state === RunState.Scheduled ? "hidden" : ""}
            >
              <FileText />
            </Button>
          </div>
          <div
            id={`logs-${run.id}`}
            className="col-start-2 relative hidden group bg-black text-sm text-gray-400 h-72 max-w-0 min-w-full -mt-24"
          >
            {!run.log ? (
              <div className="flex w-full h-full items-center justify-center">
                <span className="italic">No logs available</span>
              </div>
            ) : (
              <>
                <pre className="p-4 font-mono block h-full overflow-auto">{run.log}</pre>
                <Button
                  className="absolute top-2 right-4 hidden group-hover:block"
                  cva={{
                    intent: "icon",
                    size: "small2",
                  }}
                  title="Copy to clipboard"
                  onClick={async () => {
                    try {
                      await navigator.clipboard.writeText(run.log!);
                      toast({
                        title: "Copied to clipboard",
                        variant: "info",
                      });
                    } catch (error) {
                      toast({
                        title: "Could not copy...",
                        variant: "error",
                      });
                    }
                  }}
                >
                  <Copy size={20} />
                </Button>
              </>
            )}
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};
