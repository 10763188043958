import _ from "lodash";
import { useMemo } from "react";

import { ColumnInput, gql, useFragment } from "@/apis/nannyml";
import { DataSourceOverview, useDataSourceDetails } from "@/components/DataSource";
import { SchemaConfiguration, SchemaTable } from "@/components/Schema";
import { problemTypeColumns } from "@/domains/monitoring";
import { cn } from "@/lib/utils";

import { EditModelControls, Subtitle, Title, useModelEditor } from "./EditModel";

const monitoringModelDataSourcesFragment = gql(/* GraphQL */ `
  fragment MonitoringModelDataSources on Model {
    dataSources {
      ...DataSourceDetails
    }
  }
`);

export const DataSettings = ({ className }: { className?: string }) => {
  return (
    <div className={cn("p-4 flex flex-col gap-4", className)}>
      <SchemaSettings />
      <EditModelControls className="sticky bottom-0 py-4 -my-4 bg-dark" />
      <DataSourceSettings />
    </div>
  );
};

const SchemaSettings = () => {
  const editor = useModelEditor();
  const dataSources = useDataSourceDetails(useFragment(monitoringModelDataSourcesFragment, editor.model).dataSources);
  const columnConfig = problemTypeColumns[editor.model.problemType];

  // Memoize the reference data source ID as the identifier information is lost in the input value once the user makes
  // a change to the schema
  const [referenceId, referenceHead] = useMemo(() => {
    const referenceDs = dataSources.find((ds) => ds.hasReferenceData)!;
    return [referenceDs.id, referenceDs.head ? JSON.parse(referenceDs.head) : []];
  }, []);

  const referenceDs = dataSources.find((ds) => ds.id === referenceId)!;

  const onSchemaChange = (columns: ColumnInput[]) => {
    const newSchema = Object.fromEntries(columns.map((c) => [c.name, c]));
    editor.onModelChange({
      dataSources: dataSources.map((ds) => ({
        id: ds.id,
        columns: ds.columns.map((c) => ({
          name: c.name,
          columnType: newSchema[c.name].columnType,
          columnFlags: newSchema[c.name].columnFlags,
        })),
      })),
    });
  };

  return (
    <>
      <Title>Schema</Title>
      <Subtitle>Configure model schema by assigining the appropriate type for each column</Subtitle>
      <SchemaConfiguration
        className="justify-start mb-4"
        columnConfig={columnConfig}
        schema={referenceDs.columns}
        onSchemaChange={onSchemaChange}
      />
      <SchemaTable
        key={editor.key}
        columnConfig={columnConfig}
        schema={referenceDs.columns}
        head={referenceHead}
        onSchemaChange={onSchemaChange}
      />
    </>
  );
};

const DataSourceSettings = ({ className }: { className?: string }) => {
  const editor = useModelEditor();
  const dataSources = useFragment(monitoringModelDataSourcesFragment, editor.unchangedModel).dataSources;

  return (
    <>
      <Title>Data sources</Title>
      <Subtitle>Add data to the data sources associated with the model</Subtitle>
      <DataSourceOverview className={className} dataSources={dataSources} />
    </>
  );
};
