import { ObjectValues } from "@/constants/enums";

export const AggregationOptions = {
  Daily: "daily",
  Weekly: "weekly",
  Monthly: "monthly",
  Quarter: "quarter",
  Yearly: "yearly",
  NumberOfRows: "number_of_rows",
} as const;

export type TypeAggregationOptions = ObjectValues<typeof AggregationOptions>;

export const AggregationOptionsValues = Object.values(AggregationOptions);

export const DisabledAggregationOptionsValues = [
  // AggregationOptions.NumberOfRows,
  // AggregationOptions.NumberOfChunks,
] as TypeAggregationOptions[];

export const EnabledAggregationOptionsValues = AggregationOptionsValues.filter(
  (opt) => !DisabledAggregationOptionsValues.includes(opt)
);
