import { CloudOffIcon, FrownIcon, KeyRoundIcon } from "lucide-react";
import { useRouteError } from "react-router-dom";

const isSaasApplication = (): boolean => window.location.hostname.includes("app.nannyml.com");

export const ErrorPage = ({ error }: { error?: any }) => {
  const routeError: any = useRouteError();

  if (!error) {
    error = routeError;
  }

  if (error.status === 404) {
    return (
      <div id="error-page" className="bg-primaryBg h-full flex flex-col items-center justify-center">
        <FrownIcon size={128} className="text-slate-400" />
        <h3 className="text-xl font-semibold mt-8 mb-4 text-pale">Page not found</h3>
        <p className="text-pale mb-8 max-w-prose text-center">
          The page you are looking for does not exist. If you believe this is an error, please{" "}
          <a className="underline" href="mailto:support@nannyml.com">
            report it
          </a>{" "}
          and include the URL.
        </p>
      </div>
    );
  }

  if (error?.message === "Failed to fetch") {
    return (
      <div className="bg-primaryBg h-full flex flex-col items-center justify-center text-pale">
        <CloudOffIcon size={128} className="text-slate-400" />
        <p className="text-lg font-semibold mt-8 mb-3">Couldn't connect to your NannyML Cloud instance.</p>
        {isSaasApplication() ? (
          <p>
            This is probably a problem on our side.{" "}
            <a href="mailto:support@nannyml.com" className="underline">
              Contact us
            </a>{" "}
            to get support.
          </p>
        ) : (
          <p>Looks like your NannyML Cloud server didn't respond. Check the server status to verify.</p>
        )}
      </div>
    );
  }

  if (error.networkError?.result?.type === "LicenseError") {
    return (
      <div id="error-page" className="bg-primaryBg h-full flex flex-col items-center justify-center">
        <KeyRoundIcon size={128} className="text-slate-400" />
        <h3 className="text-xl font-semibold mt-8 mb-4 text-pale">License error</h3>
        <p className="text-pale mb-8 max-w-prose text-center">{error.networkError.result.detail}</p>
      </div>
    );
  }

  return (
    <div id="error-page" className="bg-primaryBg h-full flex flex-col items-center justify-center">
      <FrownIcon size={128} className="text-slate-400" />
      <h3 className="text-xl font-semibold mt-8 mb-4 text-pale">Unable to load page</h3>
      <p className="text-pale mb-8 max-w-prose text-center">
        Something went terribly wrong. If the problem persists, please{" "}
        <a className="underline" href="mailto:support@nannyml.com">
          report it
        </a>{" "}
        and include the below error.
      </p>
      <p className="font-mono font-sm text-slate-400 text-center w-5/6">{error.message}</p>
    </div>
  );
};
