import _ from "lodash";

import { SelectCheckboxList } from "@/components/dashboard/SelectCheckbox/SelectCheckboxList";

import { useFilter, useFilterConfig, useFilterContext } from "./Filters.hooks";

export type FilterTagsItemType = {
  tags: string[];
};

export type FilterTagsConfigType = {
  tags?: string[];
};

export const FilterTags = () => {
  const [{ tags }, setFilterConfig] = useFilterConfig<FilterTagsConfigType>();
  const { items: results } = useFilterContext<FilterTagsItemType, FilterTagsConfigType>();

  useFilter<FilterTagsItemType, FilterTagsConfigType>((resultGroups, { tags }) => {
    if (!tags?.length) {
      return resultGroups;
    }

    return resultGroups.filter((results) => results.some((result) => tags.some((tag) => result.tags.includes(tag))));
  });

  const setSelectedTags = (updateTags: (currentTags: string[]) => string[]) => {
    setFilterConfig({ tags: updateTags(tags ?? []) });
  };
  const availableTags = _.uniq(results.flatMap((result) => result.tags));

  return (
    <SelectCheckboxList
      groupClassName="max-h-100 overflow-y-auto"
      values={availableTags}
      selectedValues={tags}
      setSelectedValues={setSelectedTags}
      showSearch={true}
      showSelectOnlyCurrent={true}
      searchClassName="border-0"
      emptyPlaceholder="No tags found."
    />
  );
};
