import { useSuspenseQuery } from "@apollo/client";
import _ from "lodash";
import {
  BarChart4Icon,
  FileCheckIcon,
  FileTextIcon,
  FunctionSquareIcon,
  ScatterChartIcon,
  SettingsIcon,
  TrendingUpIcon,
} from "lucide-react";
import React, { PropsWithChildren } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { RouteObject } from "react-router-dom";

import { gql } from "@/apis/nannyml";
import { Divider } from "@/components/Divider";
import { NavGroup, NavItem, Navigation } from "@/components/Navigation";
import { RequestStateLayout } from "@/components/dashboard/RequestStateLayout/RequestStateLayout";
import { useParamsModelId } from "@/routes/useParamsModelId";

import { ConceptDriftSettings } from "./ConceptDrift";
import { CovariateShiftSettings } from "./CovariateShift";
import { DataSettings } from "./Data";
import { DataQualitySettings } from "./DataQuality";
import { DescriptiveStatisticsSettings } from "./DescriptiveStatistics";
import { ModelEditor } from "./EditModel";
import { GeneralSettings } from "./General";
import { PerformanceSettings } from "./Performance";

const getMonitoringModelSettings = gql(/* GraphQL */ `
  query GetMonitoringModelSettings($modelId: Int!) {
    monitoring_model(id: $modelId) {
      ...MonitoringModelSettings
    }
  }
`);

const modelRoutes: RouteObject[] = [
  {
    path: "",
    element: <Navigate to="general" replace />,
  },
  {
    path: "general",
    element: <GeneralSettings />,
    handle: {
      breadcrumb: "General",
      icon: SettingsIcon,
    },
  },
  {
    path: "data",
    element: <DataSettings />,
    handle: {
      breadcrumb: "Data",
      icon: FileTextIcon,
    },
  },
];

const analysisRoutes: RouteObject[] = [
  {
    path: "performance",
    element: <PerformanceSettings />,
    handle: {
      breadcrumb: "Performance",
      icon: TrendingUpIcon,
    },
  },
  {
    path: "concept-drift",
    element: <ConceptDriftSettings />,
    handle: {
      breadcrumb: "Concept drift",
      icon: FunctionSquareIcon,
    },
  },
  {
    path: "covariate-shift",
    element: <CovariateShiftSettings />,
    handle: {
      breadcrumb: "Covariate shift",
      icon: BarChart4Icon,
    },
  },
  {
    path: "descriptive-statistics",
    element: <DescriptiveStatisticsSettings />,
    handle: {
      breadcrumb: "Descriptive statistics",
      icon: ScatterChartIcon,
    },
  },
  {
    path: "data-quality",
    element: <DataQualitySettings />,
    handle: {
      breadcrumb: "Data quality",
      icon: FileCheckIcon,
    },
  },
];

export const ModelSettings = () => (
  <React.Suspense fallback={<RequestStateLayout isLoading={true} />}>
    <ModelSettingsContainer />
  </React.Suspense>
);

const ModelSettingsContainer = ({ children }: PropsWithChildren) => {
  const modelId = useParamsModelId();
  const {
    data: { monitoring_model: model },
    error,
  } = useSuspenseQuery(getMonitoringModelSettings, {
    variables: { modelId },
  });

  if (!model) {
    throw new Error("Unable to load model settings: " + error?.message);
  }

  const getUrl = (section: string) => `/monitoring/model/${modelId}/settings/${section}`;

  return (
    <ModelEditor className="flex h-full" model={model}>
      <Navigation className="min-w-fit">
        <NavGroup title="Model settings">
          {modelRoutes.map(
            ({ path, handle }) =>
              handle && (
                <NavItem key={path} Icon={handle.icon!} label={handle?.breadcrumb as string} link={getUrl(path!)} />
              )
          )}
        </NavGroup>
        <NavGroup title="Analysis settings">
          {analysisRoutes.map(
            ({ path, handle }) =>
              handle && (
                <NavItem key={path} Icon={handle.icon!} label={handle?.breadcrumb as string} link={getUrl(path!)} />
              )
          )}
        </NavGroup>
      </Navigation>
      <Divider orientation="vertical" margin="none" />
      <div className="grow overflow-auto">
        <Outlet />
      </div>
    </ModelEditor>
  );
};

ModelSettings.routes = modelRoutes.concat(analysisRoutes);
