import { HelpCircleIcon, LogOutIcon, UserIcon } from "lucide-react";
import { Suspense, useMemo } from "react";
import { Link, RouteObject, useMatches, useNavigate } from "react-router-dom";

import { SimpleTooltip } from "@/DesignSystem/nanny/SimpleTooltip/SimpleTooltip";
import { useAuth } from "@/apis/nannyml/auth";
import { Breadcrumb } from "@/components/Breadcrumb";
import { Tabs, TabsList, TabsTrigger } from "@/components/Tabs";
import { ApplicationVersion } from "@/components/Version";
import { Button } from "@/components/common/Button";
import { routes } from "@/routes/routes";

const getRootRoutes = (routes: RouteObject[]): RouteObject[] => {
  const rootRoutes: RouteObject[] = [];
  routes.forEach((route) => {
    if (route.handle?.root) {
      rootRoutes.push(route);
    }
    if (route.children) {
      rootRoutes.push(...getRootRoutes(route.children));
    }
  });
  return rootRoutes;
};

export const Header = ({ children }: { children: JSX.Element }) => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const matches = useMatches();

  const tab = matches.find((m) => m.handle?.root)?.pathname;
  const roots = useMemo(() => getRootRoutes(routes), []);

  return (
    <div className="flex flex-col h-full">
      <div className="px-4 min-h-[64px] flex items-center border-b border-gray-700 sticky top-0">
        <Link to={"/monitoring"} className={"invisible md:visible"}>
          <img src="/nannyml-logo-icon.png" className="w-10 h-10" />
        </Link>
        <Tabs className="self-end ml-8 -mb-0.5 mr-auto" value={tab ?? ""}>
          <TabsList className="border-b-0 gap-8 mb-0">
            {roots.map((route) => (
              <TabsTrigger
                key={route.path}
                className="text-base p-2"
                value={route.path!}
                onClick={() => navigate(route.path!)}
              >
                {route.handle?.root && route.handle?.title}
              </TabsTrigger>
            ))}
          </TabsList>
        </Tabs>

        <div className="ml-auto flex gap-3 items-center">
          <Link
            to={`${tab}/add`}
            className="p-2 px-4 rounded bg-highlightPale border border-transparent hover:opacity-90 text-lg"
          >
            Add new {tab === "/experiment" ? "experiment" : "model"}
          </Link>

          <SimpleTooltip tooltipContent={"Account settings"}>
            <Link
              to={"/settings"}
              aria-label="profile"
              className="p-2 rounded bg-dark border border-transparent hover:border-cyan-400"
            >
              <UserIcon />
            </Link>
          </SimpleTooltip>

          <SimpleTooltip tooltipContent={"Help"}>
            <Link
              to="/help"
              aria-label="help"
              className="p-2 rounded bg-dark border border-transparent hover:border-cyan-400"
            >
              <HelpCircleIcon className="w-7 h-7" />
            </Link>
          </SimpleTooltip>

          <SimpleTooltip tooltipContent={"Log out"}>
            <Button
              aria-label="logout"
              onClick={() => logout()}
              className="p-2 rounded bg-dark border border-transparent hover:border-cyan-400"
            >
              <LogOutIcon className="w-6 h-6" />
            </Button>
          </SimpleTooltip>
        </div>
      </div>
      <Breadcrumb className="px-4 py-2 bg-transparent" />
      <div className="flex-grow flex flex-col min-h-0 bg-dark overflow-auto">{children}</div>
      <Suspense fallback={<></>}>
        <span className="px-2 py-1 text-xs bg-dark text-gray-400 absolute bottom-3 right-2">
          NannyML Cloud <ApplicationVersion />
        </span>
      </Suspense>
    </div>
  );
};
