import { useSuspenseQuery } from "@apollo/client";
import { ResultOf } from "@graphql-typed-document-node/core";
import { useParams } from "react-router-dom";

import { PerformanceMetric, gql, useFragment } from "@/apis/nannyml";
import { FilterMetrics, FilterTags, PersistentFilterProvider } from "@/components/Filters";
import { LabeledField } from "@/components/LabeledField";
import { PlotView } from "@/components/PlotView";
import { PanelItem } from "@/components/ResizablePanel";
import { FilterBayesianSortOrder } from "@/components/bayesian";
import { FilterBayesianStatus } from "@/components/bayesian";
import {
  BayesianPlotConfigContextProvider,
  BayesianPlotDatasetsConfig,
  BayesianPlotElementsConfig,
  BayesianPlotTypesConfig,
} from "@/components/bayesian/PlotConfig";
import { EvaluationPerformanceResultPlot } from "@/components/evaluation";
import { BayesianSortOrder } from "@/constants/bayesian";
import { performanceMetricLabels } from "@/formatters/monitoring";
import { useEvaluationModelId } from "@/hooks/evaluation";

const evaluationPerformanceResultFilterFragment = gql(/* GraphQL */ `
  fragment EvaluationPerformanceResultFilterFragment on EvaluationPerformanceResult {
    metricName: metric
    status
    tags
    latestHdi {
      hdiLower
      hdiUpper
    }
  }
`);

const getEvaluationModelResultsQuery = gql(/* GraphQL */ `
  query GetEvaluationModelResults($modelId: Int!) {
    evaluation_model(id: $modelId) {
      results {
        metric
        ...EvaluationPerformanceResultPlotFragment
        ...EvaluationPerformanceResultFilterFragment
      }
    }
  }
`);

export const EvaluationModelResultsRouteName = () => {
  const { resultView } = useParams();
  return <span className="capitalize">{resultView ?? "undefined"}</span>;
};

export const EvaluationModelResultsRoute = () => {
  const modelId = useEvaluationModelId();
  const { resultView } = useParams();

  const {
    data: { evaluation_model: model },
  } = useSuspenseQuery(getEvaluationModelResultsQuery, {
    variables: { modelId },
  });

  if (!resultView) {
    throw new Error("No result view specified");
  }
  if (!model) {
    throw new Error("Model not found");
  }

  const results = useFragment(evaluationPerformanceResultFilterFragment, model.results);

  return (
    <BayesianPlotConfigContextProvider storeName={resultView}>
      <PersistentFilterProvider storeName={`EvaluationResultFilterStore.${resultView}`} items={results}>
        <PlotView
          autoSaveId="EvaluationResultView"
          filters={
            <>
              <PanelItem title="Metrics">
                <FilterMetrics metricLabels={performanceMetricLabels} />
              </PanelItem>
              <PanelItem title="Evaluation status">
                <FilterBayesianStatus />
              </PanelItem>
              <PanelItem title="Tags">
                <FilterTags />
              </PanelItem>
            </>
          }
          toolbar={
            <LabeledField label="Sort by" className="mr-auto">
              <FilterBayesianSortOrder
                getMetricLabel={(metricName) => performanceMetricLabels[metricName as PerformanceMetric]}
              />
            </LabeledField>
          }
          config={
            <>
              <PanelItem title="Datasets">
                <BayesianPlotDatasetsConfig />
              </PanelItem>
              <PanelItem title="Plots">
                <BayesianPlotTypesConfig />
              </PanelItem>
              <PanelItem title="Plot elements">
                <BayesianPlotElementsConfig />
              </PanelItem>
            </>
          }
          PlotComponent={EvaluationPerformanceResultGroupPlot}
          plotHeight={540}
          getKey={(result) => result.metric}
        />
      </PersistentFilterProvider>
    </BayesianPlotConfigContextProvider>
  );
};

export const EvaluationPerformanceResultGroupPlot = (props: {
  className?: string;
  results: NonNullable<ResultOf<typeof getEvaluationModelResultsQuery>["evaluation_model"]>["results"];
}) => <EvaluationPerformanceResultPlot className={props.className} result={props.results[0]} />;
